// "temp" models are types used for ephemeral front-end objects
//  that will be sent to the backend to be created. Therefore,
//  ids can be null on these objects.

// The normal models represent actual objects corresponding
//  to database entries. Therefore, ids must be present.


export enum NODE_TYPES {
  company = 'company',
  division = 'division',
  site = 'site',
  location = 'location',
  equipment = 'equipment'
}

export interface Logo {
  logo: any
  company: string
}


export interface TempAsset {
  uid?: string,
  path: string,
  model_input?: number,
  node_type: string,
  external_id?: string,
  create?: boolean
  deleted?: boolean
}

export interface Asset extends TempAsset{
  uid: string,
  model_input: number,
  external_id: string,
  parent_path: string,
  name_list: string[],
  path_title: string,
}


import {createSlice} from '@reduxjs/toolkit';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ModelsState {
}

const initialState: ModelsState = {
};

const modelsSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
  }
});


// eslint-disable-next-line no-empty-pattern
export const {  } = modelsSlice.actions;

export default modelsSlice.reducer;

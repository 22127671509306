import {connect} from 'react-redux';
import {setAssetFilters} from './appSlice';
import App from './app';

const mapDispatchToProps = { setAssetFilters };
const mapStateToProps = (state: any) => ({
  appState: state.app
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

import React from 'react';
import {FaultSimilarity, MAINTENANCE_TYPES} from '../../models';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {formatTimestampToString, isLoading} from '../../util/helpers';

interface Props {
  similarities?: FaultSimilarity[]
}

const useStyles = makeStyles(theme => {return {
  headerRow: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.5),
      whiteSpace: 'nowrap',
    }
  },
  bodyRow: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.5),
    }
  }
}});

function FaultSimilarityTable(props: Props) {
  const classes = useStyles();
  const {
    similarities
  } = props;

  // Loading state
  if (isLoading(similarities)) {
    return <Skeleton variant='rect' animation='wave' style={{
      width: '100%'
    }}/>
  }

  // No data
  if (!similarities || !similarities.length) {
    return<div>
      No corresponding data
    </div>;
  }

  const similarEvents: FaultSimilarity[] = similarities.slice().sort(
    (a, b) => b.similarity - a.similarity
  );

  return (
    <>
      {similarEvents.length === 0 && <span>None</span>}
      {similarEvents.length > 0 &&
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell>Rank</TableCell>
            <TableCell>WO Num</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Lead Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {similarEvents.map((similarEvent, index) => (
            <TableRow key={similarEvent.id} className={classes.bodyRow}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{similarEvent.maintenance_data.external_id}</TableCell>
              <TableCell>{MAINTENANCE_TYPES[similarEvent.maintenance_data.type as keyof typeof MAINTENANCE_TYPES]}</TableCell>
              <TableCell>{similarEvent.maintenance_data.asset.path_title}</TableCell>
              <TableCell>{
                similarEvent.maintenance_data.end_timestamp
                  ? formatTimestampToString(similarEvent.maintenance_data.end_timestamp)
                  : 'N/A'
              }</TableCell>
              <TableCell>{similarEvent.maintenance_data.title}</TableCell>
              <TableCell>{similarEvent.time_prior}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }
    </>
  )
}

export default FaultSimilarityTable;
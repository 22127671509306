import {useTheme} from "@material-ui/core";
import {getTime} from 'date-fns';
import {Options} from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, {useEffect, useState} from 'react';

import {AlertStatusHistory, STATUS_COLORS, TimeRange,} from '../../models';

interface Props {
  title?: string,
  titleJustify?: 'left' | 'center' | 'right',
  statusHistory: AlertStatusHistory[],
  timeRange: TimeRange,
  updateTimeRange: Function,
}

function AlertStatusHistoryGraph(props: Props) {
  const theme = useTheme();
  const {
    titleJustify = 'left',
    statusHistory,
    timeRange,
    updateTimeRange,
  } = props;

  let series: any;

  const initialOptions = initChartOptions(statusHistory);

  if (!!timeRange && !!initialOptions) {
    Object.assign(initialOptions.xAxis, {
      min: timeRange.start,
      max: timeRange.end,
    });
  }

  Object.assign(initialOptions.xAxis, createStatusHistoryPlotBands(statusHistory));

  // only need to pass in a partial update here:
  // https://www.npmjs.com/package/highcharts-react-official#optimal-way-to-update
  const [chartOptions, setChartOptions] = useState(initialOptions);
  useEffect(() => {
    setChartOptions({
      xAxis: {
        min: timeRange.start,
        max: timeRange.end,
      },
    });
  }, [timeRange.start, timeRange.end]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={chartOptions}
    />
  );

  function initChartOptions(statusHistory: AlertStatusHistory[]): Options {
    //populate series data so that stuff gets plotted
    series = statusHistory.map((statusChange) => {
      return {name: statusChange.flag_event, x: statusChange.timestamp * 1000, y: 0};
    });

    series.push({x: getTime(timeRange.end), y:0});

    return {
      series,
      tooltip: {
        enabled: false,
      },
      title: {
        text: 'Status History',
        align: titleJustify || 'left',
        style: {
          color: theme.graphs.appText,
          fontSize: theme.graphs.fontSize,
          fontFamily: theme.typography.fontFamily
        },
      },
      chart: {
        type: 'scatter',
        backgroundColor: theme.graphs.chartBackground,
        plotBackgroundColor: theme.palette.success.main,
        borderRadius: theme.graphs.borderRadius,
        marginRight: theme.graphs.marginRight,
        marginLeft: theme.graphs.marginLeft,
        height: 80,
        animation: false,
      },
      credits: { enabled: false },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 0,
          states: {
            hover: {
              enabled: false
            }
          },
          marker: {
            enabled: false
          }
        },
      },
      xAxis: {
        type: 'datetime',
        // plotBands: drawStatusBands(),
        minTickInterval: 60 * 1000,
        startOnTick: false,
        endOnTick: false,
        tickColor: theme.graphs.dimmerText,
        tickWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        ordinal: false,
        min: null,
        max: null,
        events: { setExtremes: setMinMax },
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      scrollbar: { enabled: false },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      }
    };
  }

  function createStatusHistoryPlotBands(statusHistory: AlertStatusHistory[]) {
    const result:
      Array<{
        color: string;
        from: number;
        to: number;
        zIndex: number;
        events: any
      }> = [];

    const alertMinMax = series.reduce((a: any, b: any) => {
      if (b.name in a){
        a[b.name] = {
          min: Math.min(a[b.name].min, b.x),
          max: Math.max(a[b.name].max, b.x),
        }
      } else if (b.name) {
        a[b.name] = {
          min: b.x,
          max: b.x,
        }
      }
      return a
    }, {});

    // sort the status history by created_date before loop
    const sortedHistory = statusHistory.slice().sort((status1, status2) => status1.timestamp - status2.timestamp);
    sortedHistory.forEach((statusChange: AlertStatusHistory, index: number) => {

      let toValue: number;
      // add end dates to status history, if its the most recent event then make the end value now
      if (index < sortedHistory.length - 1) {
        toValue = sortedHistory[index + 1].timestamp * 1000
      } else {
        toValue = getTime(Date.now())
      }

      let band: any = {
        color: theme.palette[STATUS_COLORS[statusChange.status]].main,
        from: statusChange.timestamp * 1000,
        to: toValue,
        zIndex: 0,
      };

      if (statusChange.status !== 'CLOSED') {
        band = {...band, events: {
          mouseover: function(this: any) {
            const chart = this.axis.chart;

            chart.customLabel = chart.renderer.label(
                `<span style="color: black">Alert ID: ${statusChange.flag_event}</span>`,
                this.axis.toPixels(alertMinMax[statusChange.flag_event].max < timeRange.end ?
                  (alertMinMax[statusChange.flag_event].min + alertMinMax[statusChange.flag_event].max)/2 :
                  (alertMinMax[statusChange.flag_event].min + timeRange.end)/2,
                  true),
                12.5,
                '4rem',
                50,
                undefined,
                true
              )
              .attr({
                fill: '#e8e3e3',
                padding: 8,
                zIndex: 6
              })
              .add()
          },
          mouseout: function(this: any) {
            const chart = this.axis.chart;
            if (chart.customLabel) {
              chart.customLabel.destroy();
            }
          }
        }}
      }

      result.push(band)
    });
    return {
      plotBands: result
    }
  }

  function setMinMax(hcEvent: Highcharts.ExtremesObject) {
    if (updateTimeRange && hcEvent) {
      updateTimeRange({ start: hcEvent.min, end: hcEvent.max });
    }
  }
}

export default AlertStatusHistoryGraph;

import {createSlice} from '@reduxjs/toolkit';
import {Asset} from "./models";

export interface AppState {
  assetFilters: Array<Asset>,
  sidebar: boolean
}

const initialState: AppState = {
  assetFilters: [],
  sidebar: false
};

const appSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAssetFilters(state, action) {
      const asset = action.payload.asset;
      const parent = action.payload.parent;
      switch (action.payload.type) {
        case 'select':
          state.assetFilters = state.assetFilters.filter(
            a => a.path !== asset.parent_path
          );
          state.assetFilters.push(asset);
          break;
        case 'deselect':
          state.assetFilters = state.assetFilters.filter(a =>
            a.path !== asset.path
          );
          // If no sibling selected, add parent node
          if (parent && !state.assetFilters.find(a =>
            a.parent_path === asset.parent_path && a !== asset
          )) {
            state.assetFilters.push(parent);
          }
          break;
        default:
          break;
      }
    }
  }
});

export const { setAssetFilters } = appSlice.actions;
export default appSlice.reducer;

import React, {useState} from 'react';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  useTheme,
} from "@material-ui/core";
import {Alert, ALERT_CLASS, ALERT_EVAL, ALERT_STATUS, STATUS_COLORS} from "../../models";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {listToOptions} from "../../util/helpers";
import MuiTableCell from "@material-ui/core/TableCell";
import StatusChip from "../ui/statusChip";

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
      '&:last-child': {
        width: '100%'
      },
    },
    '& td': theme.fontStyling.table,
    '& li': theme.fontStyling.table,
    backgroundColor: theme.graphs.chartPlotBackground
  },
  selectLabel: {
    fontSize: theme.typography.fontSize
  },
  select: {
    width: '100%',
    fontSize: theme.fontStyling.table.fontSize,
  },
  checkbox: {
    padding: 0
  },
  saveButton: {
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  container: {
    width: '100%',
    backgroundColor: theme.graphs.chartBackground,
    padding: theme.spacing(1.5),
  },
  title: {
    fontSize: theme.fontStyling.alertTitle.fontSize,
    display: 'block',
    paddingBottom: theme.spacing(1)
  }
}));

interface Props {
  alert: Alert,
  onSubmit: (alert: Alert) => void,
  direction?:  'row' | 'row-reverse' | 'column' | 'column-reverse'
}

function AlertForm(props: Props) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    alert,
    onSubmit,
  } = props;

  const [ newAlert, updateAlert ] = useState({...alert, isDirty: false});

  let isClosed = ALERT_STATUS[newAlert.status] === ALERT_STATUS.CLOSED;
  let invalidEvaluation = isClosed && !newAlert.evaluation;

  const updateField = (field: string, value: any) => {
    updateAlert(priorState => {
      const newState = Object.assign({}, priorState, {
        [field]: value,
        isDirty: false
      });
      Object.keys(alert).forEach(key => {
        if((alert as any)[key] !== (newState as any)[key]) {
          newState.isDirty = true;
        }
      });
      return newState;
    })
  };

  const saveAlert = (event: any) => {
    if (!invalidEvaluation) {
      newAlert.classification = newAlert.classification ? newAlert.classification : null;
      newAlert.evaluation = newAlert.evaluation ? newAlert.evaluation : null;
      delete newAlert.isDirty;
      onSubmit(newAlert)
    }
  };

  return (
    <Card className={classes.container}>
      <span className={classes.title}>Alert Management</span>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Alert Status</TableCell>
              <TableCell align="right">
                <Select
                  className={classes.select}
                  value={newAlert.status || ''}
                  onChange={(event) => updateField(
                    'status', event.target.value
                  )}
                >
                  {Object.keys(ALERT_STATUS).map((key: string) => {
                    const status = key as keyof typeof ALERT_STATUS;
                    return (
                      <MenuItem key={key} value={key} className={classes.select}>
                        <StatusChip
                          color={STATUS_COLORS[status]}
                          status={ALERT_STATUS[status]}
                          width={10}
                          size={'small'}
                        />
                      </MenuItem>
                    )
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anomaly <br/> Classification</TableCell>
              <TableCell align="right">
                <Select
                  displayEmpty
                  className={classes.select}
                  value={newAlert.classification || ''}
                  onChange={(event) => updateField(
                    'classification', event.target.value || null
                  )}
                >
                  <MenuItem className={classes.select} value=''>None</MenuItem>
                  {listToOptions(Object.keys(ALERT_CLASS), ALERT_CLASS, theme.fontStyling.table.fontSize)}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Approved</TableCell>
              <TableCell align="right">
                <Checkbox
                  className={classes.checkbox}
                  checked={newAlert.approved || false}
                  color='primary'
                  onChange={(event) => updateField(
                    'approved', event.target.checked
                  )}
                  size='small'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anomaly <br/> Evaluation</TableCell>
              <TableCell align="right">
                <FormControl className={classes.select} error={invalidEvaluation}>
                  <Select
                    displayEmpty
                    className={classes.select}
                    value={newAlert.evaluation ? ALERT_EVAL[newAlert.evaluation] : ''}
                    onChange={(event) => updateField(
                      'evaluation', event.target.value || null
                    )}
                  >
                    <MenuItem className={classes.select} value=''>None</MenuItem>
                    {listToOptions(Object.keys(ALERT_EVAL), ALERT_EVAL, theme.fontStyling.table.fontSize)}
                  </Select>
                  <FormHelperText className={classes.select}>Required if Closing Alert</FormHelperText>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                  <Button variant="contained" className={classes.saveButton} onClick={saveAlert} size='small' disabled={!newAlert.isDirty}> Save</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default AlertForm;
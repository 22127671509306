import React, {ChangeEvent, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {TimeRange} from '../../models';
import {Box, Grid, Tooltip} from '@material-ui/core';
import {formatTimestampToString} from '../../util/helpers';

const useStyles = makeStyles(theme => ({
  wrapper: {
    // position: 'absolute'
    // top: 'auto',
    // bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.graphs.chartBackground,
    borderRadius: theme.graphs.borderRadius,
  },
  sliderBox: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

interface Props {
  timeRange: TimeRange,
  updateTimeRange: Function
}

function SliderLabel(props: any) {
  const {
    children,
    open,
    value
  } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
      {children}
    </Tooltip>
  )
}

function TimeRangeSelector(props: Props) {
  const classes = useStyles();
  const { timeRange, updateTimeRange } = props;

  const [tempTimeRange, updateTempTimeRange] = useState<any>({});

  useEffect(() => {
    updateTempTimeRange(timeRange);
  }, [timeRange]);

  function handleChange(event: ChangeEvent<{}>, newRange: number | Array<number>) {
    if (newRange && typeof(newRange) == 'object' && newRange.length === 2) {
      updateTempTimeRange({...timeRange, start: newRange[0], end: newRange[1] });
    }
  }

  function handleCommittedChange(event: ChangeEvent<{}>, newRange: number | Array<number>) {
    if (newRange && typeof(newRange) == 'object' && newRange.length === 2) {
      updateTimeRange({start: newRange[0], end: newRange[1] });
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Typography variant='subtitle1'>Time Range</Typography>
      <Grid container className={classes.sliderBox} spacing={2} alignItems={'center'}>
        <Grid item xs={2}>
          <Typography align='center' variant='subtitle2'>
            {formatTimestampToString(timeRange.start)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Slider
            value={[tempTimeRange.start, tempTimeRange.end]}
            min={tempTimeRange.min}
            max={tempTimeRange.max}
            step={1000 * 60 * 60}
            onChange={handleChange}
            onChangeCommitted={handleCommittedChange}
            aria-labelledby="range-slider"
            valueLabelFormat={formatTimestampToString}
            getAriaValueText={formatTimestampToString}
            ValueLabelComponent={SliderLabel}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography align='center' variant='subtitle2'>
            {formatTimestampToString(timeRange.end)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TimeRangeSelector;

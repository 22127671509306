import React, {useEffect, useState} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {makeStyles} from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const rootStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(1)}px 0px`,
    '&.Mui-expanded': {
      margin: `${theme.spacing(1)}px 0px`
    },
    '&:first-child': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

const summaryStyles = makeStyles(theme => ({
  root: {
    minHeight: 0,
    padding: theme.spacing(1),
    '& .MuiButtonBase-root': {
      padding: 0,
      margin: 0,
    },
    '&.Mui-expanded': {
      minHeight: 0,
    },
    fontSize: theme.fontStyling.alertTitle.fontSize,
  },
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    },
  }
}));

const detailStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

interface Props {
  title: string,
  children?: any,
  openDefault?: boolean,
  className?: any,
  getChildren?: Function
}

function FoldableSection(props: Props) {
  const {
    title = '',
    children = null,
    openDefault = false,
    className = '',
    getChildren
  } = props;

  const [childElements, setChildElements] = useState(children);

  useEffect(() => {
    refreshChildren();
    //eslint-disable-next-line
  }, [children, getChildren]);

  return (
    <Accordion
      classes={rootStyles()}
      defaultExpanded={openDefault}
      className={className}
      onChange={onExpandChange}
    >

      <AccordionSummary classes={summaryStyles()} expandIcon={<ExpandMoreIcon/>}>
        {title}
      </AccordionSummary>

      <AccordionDetails classes={detailStyles()}>
        {childElements || <></>}
      </AccordionDetails>

    </Accordion>
  );

  function onExpandChange(event: any, expanded: boolean) {
    refreshChildren();
  }

  function refreshChildren() {
    if(children) {
      setChildElements(children);
    } else if (getChildren) {
      setChildElements(getChildren())
    }
  }
}

export default FoldableSection;

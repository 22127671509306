import {Asset} from ".";

export enum MAINTENANCE_TYPES {
  PM = 'Preventative Maintenance',
  COR = 'Planned Corrective Maintenance',
  EMR = 'Emergency Response',
  // Unknown = 'Unknown',
  URGENT = 'Urgent',
  // SMALLPRJ = 'Small Projects',
  PRED = 'Predictive Tasks Performed',
  // PRJ = 'Capital Project',
  // NONMAINT = 'Non Maintenance/Production',
  // PDM = 'Predictive Maintenance',
  // REQ = 'Requested Maintenance Task',
  // PMCOR = 'Maintenance Task Generated From Preventive Maintenance',
  // PDMCOR = 'Maintenance Task Generated From Predictive Maintenance',
}

export interface MaintenanceData {
  uid: string,
  external_id: string,
  title: string,
  description: string,
  type: string,
  create_timestamp: number,
  start_timestamp: number,
  end_timestamp: number,
  close_timestamp: number,
  wo_label: number,
  asset: Asset,
  rank?: number,
  time_prior?: number
}

export interface MaintenanceAggregates {
  placeholder: any
}

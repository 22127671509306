import {connect} from 'react-redux';
import ModelsPage from "./Models";

const mapDispatchToProps = {  };
const mapStateToProps = (state: any) => ({
  modelState: state.model
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelsPage);

import React, {useState} from 'react';
import {Checkbox, createStyles, List, ListItem, ListItemIcon, ListItemText, Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {isLoading} from "../../util/helpers";
import {Skeleton} from "@material-ui/lab";
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.graphs.chartBackground,
      padding: theme.spacing(1.5)
    },
    listItem: {
      '& span': theme.fontStyling.table
    },
    list: {
      backgroundColor: theme.graphs.chartPlotBackground,
      overflow: 'auto',
      flexGrow: 1,
      maxHeight: '300px'
    },
    title: {
      fontSize: theme.fontStyling.alertTitle.fontSize,
      display: 'block',
      paddingBottom: theme.spacing(1)
    }
  })
);

interface Props {
  items: any[],
}

function HighchartsLegend(props: Props){
  const classes = useStyles();

  const {
    items,
  } = props;

  const [checked, setChecked] = useState<{[key: string]: boolean}>({});

  if (isLoading(items) || items.length === 0) {
    return (
      <Skeleton variant='rect' animation='wave' style={{
        height: '10rem',
        width: '100%'
      }}/>
    )
  }

  return (
    <Card className={classes.root}>
      <span className={classes.title}>Variable Selection</span>
      <List className={classes.list}>
        { items
          ?
          items.map((item) => {
            const labelId = `checkbox-list-label-${item.name}`;
            if (checked[item.name] === undefined){
              setChecked({...checked, [item.name]: item.visible});
            }
            return (
              <ListItem
                key={item.name}
                role={undefined}
                dense
                button
                style={{paddingTop: 0, paddingBottom: 0}}
                onClick={() => {
                  item.setVisible(!item.visible);
                  setChecked({...checked, [item.name]: !checked[item.name]});
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked[item.name]}
                    name={item.name}
                    tabIndex={-1}
                    disableRipple
                    color='primary'
                    size='small'
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </ListItemIcon>
                <ListItemIcon>
                  <RemoveIcon style={{color: item.color}}/>
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.name} className={classes.listItem}/>
              </ListItem>
            )
        })
          :
          null
        }
      </List>
    </Card>)
}

export default HighchartsLegend;

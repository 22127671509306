import {DataPoint} from "./GraphData";

export enum VARIABLE_TYPE {
    TIME = 'Time Series',
    WAVE = 'Waveform'
}

export enum VARIABLE_STATUS {
    STREAMING = 'Streaming',
    NOT_STREAMING = 'Not Streaming',
    NO_DATA = 'No Data',
}

export enum VARIABLE_STATUS_COLORS {
    STREAMING = 'success',
    NOT_STREAMING = 'error',
    NO_DATA = 'warning',
}

export interface Variable {
    uid: string,
    name: string,
    type: keyof typeof VARIABLE_TYPE,
}

export interface VariableStatus {
    variable: Variable,
    last_timestamp: number,
    update_interval_avg: number,
    update_interval_std: number,
    update_interval_cur: number,
    status: keyof typeof VARIABLE_STATUS,
}

export type VariableAggregates = {
    [key in keyof typeof VARIABLE_STATUS]: number
}

export interface VariableData {
    name: string,
    data: Array<DataPoint>,
}

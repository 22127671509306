import React from 'react';
import {Asset} from "../../models";
import {DataState} from "./DataSlice";
import DataTable from "./components/dataTable";

interface Props {
  dataState: DataState,
  // todo: this needs to be passed directly into the slice
  assetFilters: Asset[]
}

function DataPage(props: Props) {

  const {
    assetFilters
  } = props;

  return (
    <DataTable
      assetFilters={assetFilters}
      search
      paging
    />
  )
}

export default DataPage;

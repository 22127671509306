/*
  This file sets up default behavior for requests to the API, returning an Axios instance.
*/
import axios from 'axios';

import {
  Alert,
  AlertAggregates, AlertStatusHistory,
  Asset,
  DiagnosticVariable,
  DjangoQueryResult,
  FaultSimilarity,
  LiveDataTable,
  Logo,
  MaintenanceData,
  ModelGroup,
  ModelGroupAggregates,
  ModelGroupStatus,
  ModelOutput, ProductionRun,
  Sensor, TempAsset,
  TempModelGroup,
  Variable, VariableAggregates,
  VariableStatus,
  WaterfallData,
} from './models';
import {RawData, RawDataPoint} from "./models/RawData";

const DEFAULT_TIMEOUT = 60 * 1000; // in milliseconds
function urlConfig() {
  switch (process.env.REACT_APP_API) {
    case 'local':
      return 'http://localhost:8000/';

    case 'development':
      return 'https://dev.api.decisioniq-ai.com/';

    case 'production':
      return 'https://api.decisioniq-ai.com/';

    default:
      throw new URIError(`No API url configured for ${process.env.NODE_ENV}`)
  }
}

// You should not need to export this, anything that needs to interface with this api directly
// should be wrapped in a method and invoked accordingly
const api = axios.create({
  baseURL: urlConfig(),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  timeout: DEFAULT_TIMEOUT,
});

export function setAccessToken(token: string) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function get<T>(url: string, paramsMap?: object): Promise<T> {
  if (paramsMap) {
    const params: string = Object.entries(paramsMap).map(
        ([param, value]) => `${param}=${value}`
    ).join('&');
    if (!!params && params.length) {
      url = url.concat('?', params);
    }
  }
  return api.get(url).then(response => {
    return response.data as T;
  })
}

async function query<T>(url: string, paramsMap?: object): Promise<Array<T>> {
  return get<DjangoQueryResult<T>>(
      url, paramsMap
  ).then(query => {
    return query.results;
  });
}

async function post<T, R>(url: string, obj: T): Promise<R> {
  return api.post(
      url, obj
  ).then(res => {
    return res.data as R;
  });
}

async function patch<T, R>(url: string, pk: string | number, obj: T): Promise<R> {
  return api.patch(
    `${url}${pk}/`, obj
  ).then(res => {
    return res.data as R;
  })
}

export async function queryProductionRunPaginated(params?: object): Promise<DjangoQueryResult<ProductionRun>> {
  return get<DjangoQueryResult<ProductionRun>>(
    `live_data/production/`, params
  )}

export async function queryMaintenancePaginated(params?: object): Promise<DjangoQueryResult<MaintenanceData>> {
  return get<DjangoQueryResult<MaintenanceData>>(
    `live_data/maintenance/`, params
  )
}

export async function queryDataStatusPaginated(params?: object): Promise<DjangoQueryResult<VariableStatus>> {
  return get<DjangoQueryResult<VariableStatus>>(
    `live_data/variable/status/`, params
  )
}

export async function queryDataAggregates(params?: object): Promise<DjangoQueryResult<VariableAggregates>> {
  return get<DjangoQueryResult<VariableAggregates>>(
    `live_data/variable/aggregates/`, params
  )
}

export async function queryVariables(params?: object): Promise<Variable[]> {
  return query<Variable>(
    `live_data/variable/`, params
  );
}

export async function queryModelGroupStatusPaginated(params?: object): Promise<DjangoQueryResult<ModelGroupAggregates>> {
  return get<DjangoQueryResult<ModelGroupAggregates>>(
    `revelation/model_group/aggregates/`, params
  )
}

export async function queryModelGroupAggregates(params?: object): Promise<DjangoQueryResult<ModelGroup>> {
  return get<DjangoQueryResult<ModelGroup>>(
    `revelation/model_group/aggregates/`, params
  );
}

export async function getAlert(id: number): Promise<Alert> {
  return get<Alert>(
      `revelation/flag_events_v2/${id}/`
  );
}

export async function queryAlertsPaginated(params: object): Promise<DjangoQueryResult<Alert>> {
  return get<DjangoQueryResult<Alert>>(
      `revelation/flag_events_v2/`, params
  );
}

export async function queryAlertAggregates(params?: object): Promise<AlertAggregates> {
  return get<AlertAggregates>(
      `revelation/flag_events_v2/aggregates/`, params
  );
}

export async function patchAlert(id: number, data: object): Promise<Alert> {
  return patch<object, Alert>(
    `revelation/flag_events_v2/`, id, data
  )
}

export async function queryDataTables(params: object): Promise<LiveDataTable[]> {
  return query<LiveDataTable>(
      `revelation/facility_data_table/`, params
  )
}

export async function getWaterfallData(alertId: number): Promise<WaterfallData | {}> {
  return get<WaterfallData>(
      `revelation/waterfall_data/${alertId}/`
  ).catch(error => {
    if (error.response.status === 404) {
      console.debug('No waterfall data found for this alert.');
    } else {
      console.error('Request for Waterfall data failed.');
      console.error(error);
    }
    return {};
  });
}

export async function getSensor(sensorId: number): Promise<Sensor | {}> {
  return get<Sensor>(
    `revelation/sensor/${sensorId}`
  ).catch(error => {
    if (error.response.status === 404) {
      console.debug('No sensor for this alert.');
    } else {
      console.error('Request for sensor failed.');
      console.error(error);
    }
    return {};
  });
}

export async function queryModelOutput(params?: object): Promise<ModelOutput[]> {
  return get<ModelOutput[]>(
    `revelation/model_output/`, params
  );
}

export async function queryDiagnosticVariables(params?: object): Promise<DiagnosticVariable[]> {
  return query<DiagnosticVariable>(
    `revelation/diagnostic_variable/`, params
  );
}

export async function queryMaintenanceData(params?: object): Promise<MaintenanceData[]> {
  return query<MaintenanceData>(
    `live_data/maintenance/`, params
  );
}

export async function queryMaintenanceDataFieldOptions(field: string): Promise<string[]> {
  return get<string[]>(
    `live_data/maintenance/`, {distinct: field}
  );
}

export async function queryMaintenanceSimilarities(params?: object): Promise<FaultSimilarity[]> {
  return query<FaultSimilarity>(
    `revelation/maintenance_similarity/`, params
  );
}


export async function postModelGroup(modelGroup: TempModelGroup): Promise<ModelGroup> {
  return post<TempModelGroup, ModelGroup>(
    `revelation/model_group/`, modelGroup
  )
}

export async function queryLiveVariableData(params?: object): Promise<Array<RawDataPoint>> {
  return get<Array<RawDataPoint>>(
    'live_data/', params
  );
}

export async function uploadLiveVariableData(data: RawData): Promise<any> {
  return post<RawData, any>(
    `live_data/`, data
  );
}

export async function queryModelGroupStatuses(params?: object): Promise<ModelGroupStatus[]> {
  return get<ModelGroupStatus[]>(
    `revelation/model_group/`, params
  )
}

export async function getLogo(company: string): Promise<Logo> {
  return get<Logo>(
    `assets/logo/${company}`
  );
}

export async function queryAsset(params?: object): Promise<Asset[]> {
  return query<Asset>(
    `assets/asset/`, params
  )
}

export async function postAsset(asset: TempAsset): Promise<Asset> {
  return post<TempAsset, Asset>(
    `assets/asset/`, asset
  )
}

export async function getAsset(uid: string): Promise<Asset> {
  return get<Asset>(
    `assets/asset/${uid}`
  )
}

export async function patchAsset(uid: string, data: object): Promise<Asset> {
  return patch<object, Asset>(
    `assets/asset/`, uid, data
  )
}

export async function queryAlertStatusHistory(params?: object): Promise<AlertStatusHistory[]> {
  return query<AlertStatusHistory>(
    `revelation/flag_event_audit_entry/`, params
  )
}

import {connect} from 'react-redux';
import AlertsPage from "./Alerts";
import {initTimeRange, resetState, setAlert, setData, updateTimeRange} from "./AlertsSlice";

const mapDispatchToProps = { setAlert, setData, initTimeRange, updateTimeRange, resetState };
const mapStateToProps = (state: any) => ({
  alertsState: state.alerts
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsPage);

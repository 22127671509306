import React, {useEffect, useState} from 'react';
import {Asset, NODE_TYPES, TempAsset} from "../../models";
import {
  Button,
  capitalize, Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl, FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField, useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {patchAsset, postAsset} from "../../api";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '30vw'
  },
  actions: {
    padding: theme.spacing(1)
  },
  // annoying work-around: https://github.com/mui-org/material-ui/issues/14530
  typeLabel: {
    padding: theme.spacing(.5),
    backgroundColor: theme.palette.background.paper
  }
}));

export interface AssetFormProps {
  parents: Asset[],
  asset?: Asset,
  deleting?: boolean,
  open: boolean,
  closeForm: (newAsset?: Asset, parent?: Asset) => void
}

function AssetForm(props: AssetFormProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    parents,
    asset,
    deleting = false,
    open,
    closeForm
  } = props;

  const [parent, setParent] = useState<Asset>();
  const [assetType, setAssetType] = useState<NODE_TYPES>();
  const [name, setName] = useState<string>('');
  const [warningSeen, setWarningSeen] = useState(false);

  const [error, setError] = useState<any>();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (asset) {
      setParent(parents.find(p => p.path === asset.parent_path));
      setAssetType(NODE_TYPES[asset.node_type as keyof typeof NODE_TYPES]);
      setName(asset.name_list.slice(-1)[0])
    } else if(parents?.length === 1) {
      setParent(parents[0])
    }
  }, [asset, parents]);

  let action: string;
  if (asset && deleting) {
    action = 'Delete';
  } else if (asset) {
    action = 'Update';
  } else {
    action = 'Create';
  }

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.header}>
        {action} Asset
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Autocomplete
          freeSolo
          value={parent}
          disabled={!asset || deleting}
          options={parents}
          onChange={handleParentChange}
          filterOptions={(assets: Asset[], params: any) =>
            assets.filter(a =>
              a.path.includes(params.inputValue.toLowerCase())
              || a.name_list.includes(params.inputValue.toLowerCase())
            )
          }
          getOptionLabel={o => o.path}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error?.parent}
              style={{ width: '100%' }}
              helperText={asset ? 'Select a Parent Asset' : null}
              label="Parent Asset"
              margin="normal"
              variant="outlined"
            />
          )}
        />
        <FormControl variant='outlined'>
          <InputLabel className={classes.typeLabel}>
            Asset Type
          </InputLabel>
          <Select
            value={assetType || ''}
            onChange={handleTypeChange}
            error={!!error?.type}
            disabled={deleting}
          >
            {Object.entries(NODE_TYPES).reduce((items, [key, value]) => {
              if (value !== NODE_TYPES.company.valueOf()) {
                items.push((
                  <MenuItem key={key} value={key}>
                    {capitalize(value)}
                  </MenuItem>
                ));
              }
              return items;
            }, [] as JSX.Element[])}
          </Select>
          <FormHelperText>
            Select the type of Asset you are adding.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            error={!!error?.name}
            value={name}
            disabled={deleting}
            onChange={event => setName(event.target.value)}
            helperText={'The name of the new Asset.'}
            label="Name"
            margin="normal"
            variant="outlined"
          />
        </FormControl>
        <FormControlLabel
          style={!asset || parent?.path === asset?.parent_path
            ? {display: 'none'}
            : {color: error?.warning
                ? theme.palette.error.main
                : theme.palette.warning.main
              }
          }
          control={
            <Checkbox
              color='primary'
              checked={warningSeen}
              onChange={event => setWarningSeen(event.target.checked)}
            />
          }
          label={
            'WARNING: Changing the parent path of this asset could have unintended '
            +'consequences on the model assigned to it and historical results of the model. '
            +'Click the checkbox to confirm you understand this possibility.'
          }
        />
        <FormControlLabel
          style={!deleting
            ? {display: 'none'}
            : {color: error?.warning
                ? theme.palette.error.main
                : theme.palette.warning.main
              }
          }
          control={
            <Checkbox
              color='primary'
              checked={warningSeen}
              onChange={event => setWarningSeen(event.target.checked)}
            />
          }
          label={
            'WARNING: Deleting this asset will cause related data to no longer be accessbile '
            +'and will require a DecisionIQ Staff Member to recover the data if you want it back. '
            +'Click the checkbox to confirm you understand these consequences.'
          }
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant='contained' color='secondary' onClick={() => closeForm()}>
          Cancel
        </Button>
        <Button
          variant='contained' color='primary'
          onClick={submitting ? undefined : submit}
        >
          {submitting
            ? (<CircularProgress color="inherit" />)
            : (action)
          }
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleParentChange(event: any, value: any) {
    setParent(value);
  }

  function handleTypeChange(event: any) {
    setAssetType(event.target.value as NODE_TYPES)
  }

  function resetForm() {
    setParent(undefined);
    setAssetType(undefined);
    setName('');
    setWarningSeen(false);
  }

  function submit() {
    const warningSeenError = ((deleting || (asset && parent?.path !== asset?.parent_path)) && !warningSeen);
    if (!parent || !assetType || !name || warningSeenError) {
      setError({
        parent: !parent,
        assetType: !assetType,
        name: !name,
        warning: warningSeenError
      });
      return;
    }
    setError(null);

    setSubmitting(true);
    const path = `${parent.path}.` + name.toLowerCase().replace(' ', '_');
    const newAsset: TempAsset = {
      ...asset,
      path,
      node_type: assetType,
      deleted: deleting
    };
    if (asset) {
      patchAsset(asset.uid, newAsset).then(response => {
        setSubmitting(false);
        closeForm(response, parent);
        resetForm();
      })
    } else {
      postAsset(newAsset).then(response => {
        setSubmitting(false);
        closeForm(response, parent);
        resetForm();
      });
    }
  }
}

export default AssetForm;

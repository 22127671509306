import React, {ReactNode, useEffect} from "react";
import {Route, RouteComponentProps} from "react-router-dom";
import {useAuth0} from "../../auth";

interface Props {
  path: string,
  exact?: boolean,
  renderComponent: (props: any) => ReactNode,
}

function PrivateRoute(props: Props) {
  const {
    exact,
    path,
    renderComponent
  } = props;
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        redirect_uri: window.location.origin,
      })
    }
  }, [user, isLoading, isAuthenticated, loginWithRedirect, path]);

  return <Route exact={exact} path={path} render={(routeProps: RouteComponentProps<any>) =>{
    return isAuthenticated ? renderComponent(routeProps) : null;
  }}/>;
}

export default PrivateRoute;

export const MODEL_TYPE = {
  DECISION: 'decision',
  FLAGGER: 'flagger',
  DETECTION: 'detection',
};

export interface Model {
  id: number,
  name: string,
  model_input: number
}

import React, { useEffect, useState } from 'react';
import { EditCellColumnDef } from "material-table";
import {
  Box,
  Select,
  MenuItem, TextField, IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Cancel} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  pickersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  picker: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  dateDisplay: {
    display: 'inline-block',
    verticalAlign:'bottom',
  }
}));

interface Props {
  columnDef: EditCellColumnDef,
  onFilterChanged: Function
}

const units = {
  secs: 1,
  mins: 60,
  hrs: 60*60,
  days: 60*60*24,
};

function RangeFilter(props: Props) {
  const classes = useStyles();

  const {
    columnDef,
    onFilterChanged
  } = props;

  const [greaterThan, setGreaterThan] = useState<number | null>(null);
  const [fromUnits, setFromUnits] = useState<number>(units.days);
  const [lessThan, setLessThan] = useState<number | null>(null);
  const [toUnits, setToUnits] = useState<number>(units.days);

  useEffect(() => {
    if (greaterThan || lessThan) {
      const filter: any = {};
      if (greaterThan) {
        filter[`${columnDef.field}__gte`] = greaterThan * fromUnits;
      }
      if (lessThan) {
        filter[`${columnDef.field}__lte`] = lessThan * toUnits;
      }
      onFilterChanged(columnDef.tableData.id, filter)
    }
  }, [
    greaterThan, lessThan, fromUnits, toUnits,
    columnDef.field, columnDef.tableData.id, onFilterChanged
  ]);

  return (
    <>
      <Box className={classes.pickersContainer}>
        <Box className={classes.picker}>
          <TextField
            value={greaterThan || ''}
            onChange={(event: any) => event && setGreaterThan(event.target.value)}
            label='From'
            InputProps={{
              endAdornment:
                greaterThan
                  ? <IconButton onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setGreaterThan(null);
                    }}>
                      <Cancel />
                    </IconButton>
                  : null
            }}
          />
          {unitSelector(fromUnits, setFromUnits)}
        </Box>
        <Box className={classes.picker}>
          <TextField
            value={lessThan || ''}
            onChange={(event: any) => event && setLessThan(event.target.value)}
            label='To'
            InputProps={{
              endAdornment:
                lessThan
                  ? <IconButton onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setLessThan(null);
                    }}>
                      <Cancel />
                    </IconButton>
                  : null
            }}
          />
          {unitSelector(toUnits, setToUnits)}
        </Box>
      </Box>
    </>
  );

  function unitSelector(unitState: any, setUnitState: Function) {
    return (
      <Select
        value={unitState}
        onChange={(event) => setUnitState(event.target.value)}
      >
        {Object.entries(units).map(([key, value]) => (
          <MenuItem key={`unit-${key}`} value={value}>
            {key}
          </MenuItem>
        ))}
      </Select>
    )
  }
}

export default RangeFilter;

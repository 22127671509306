import {createSlice} from '@reduxjs/toolkit';
import {
  Alert, AlertStatusHistory,
  DiagnosticVariable,
  MaintenanceData,
  ModelOutput,
  Sensor, TimeRange,
  VariableData,
  WaterfallData
} from "../../models";


export interface AlertsState {
  alert?: Alert,
  waterfallData?: WaterfallData,
  sensor?: Sensor | null,
  variablesData?: VariableData[],
  flaggerScores?: ModelOutput[],
  decisionScores?: ModelOutput[],
  detectionScores?: ModelOutput[],
  diagnosticVariables?: DiagnosticVariable[],
  maintenanceData?: MaintenanceData[],
  statusHistory?: AlertStatusHistory[],
  timeRange: TimeRange,
  workOrderTypes: string[],
}

const initialState: AlertsState = {
  timeRange: {
    start: 0,
    end: 0,
    min: 0,
    max: 0,
  },
  workOrderTypes: []
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert(state, action) {
      state.alert = action.payload;
    },
    setData(state, action) {
      (state as any)[action.payload.label] = action.payload.data;
    },
    initTimeRange(state, action) {
      state.timeRange = {
        start: action.payload.start,
        end: action.payload.end,
        min: action.payload.min,
        max: action.payload.max,
      }
    },
    updateTimeRange(state, action) {
      state.timeRange = {
        start: action.payload.start,
        end: action.payload.end,
        min: state.timeRange.min,
        max: state.timeRange.max,
      }
    },
    resetState(state, action) {
      return initialState;
    }
  }
});


export const { setAlert, setData, initTimeRange, updateTimeRange, resetState } = alertsSlice.actions;

export default alertsSlice.reducer;

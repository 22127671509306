import {isUndefined} from 'lodash';
import React, {useState} from 'react';
import {format} from "date-fns";
import {MenuItem} from "@material-ui/core";
import {Asset} from "../models";

const standardDateFormat = 'M/d/yyyy h:mm aa';
export function formatDuration(durationSeconds: number) {
  if (!durationSeconds) {
    return '0 sec';
  }
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  while (durationSeconds > 0) {
    if (durationSeconds - 60*60*24 > 0) {
      days++;
      durationSeconds -= 60*60*24;
    } else if (durationSeconds - 60*60 > 0) {
      hours++;
      durationSeconds -= 60*60;
    } else if (durationSeconds - 60 > 0) {
      minutes++;
      durationSeconds -= 60;
    } else {
      seconds += durationSeconds;
      durationSeconds = 0;
    }
  }
  return (days ? `${days} d ` : '')
       + (hours ? `${hours} hr ` : '')
       + (minutes ? `${minutes} min ` : '')
       + (seconds ? `${seconds} sec`: '');
}

export function mapSeriesData(data: any, lineColor = null, seriesZIndex = 1) {
  if (!data || !data.length) return [];
  return data.map((dataSet: any) => {
    return {
      name: dataSet.name,
      data: dataSet.data && dataSet.data.map((datapoint: any) => ({
        id: datapoint[0],
        x: datapoint[0],
        y: datapoint[1],
      })).sort((a: any, b: any) => a.x - b.x),
      showInNavigator: false,
      zIndex: seriesZIndex,
      color: lineColor,
    };
  });
}

export class DynamicSpacer {
  size: number;
  setSize: any;

  constructor() {
    const [size, setSize] = useState(0);
    this.size = size;
    this.setSize = setSize;
  }

  private dynamicSpacing(el: HTMLElement | null, field: string) {
    if (!!el && !!el.childNodes.values()) {
      this.setSize((el.childNodes[0] as any)[field]);
    }
  }

  private spaceVertical(el: HTMLElement | null) {
    this.dynamicSpacing(el, 'offsetHeight')
  }

  public verticalSpacer() {
    return this.spaceVertical.bind(this);
  }

  private spaceHoriztonal(el: HTMLElement | null) {
    this.dynamicSpacing(el, 'offsetWidth')
  }

  public horizontalSpacer() {
    return this.spaceHoriztonal.bind(this);
  }
}

export function isLoading(data: any): boolean {
  return isUndefined(data);
}

export function formatTimestampToString(value: number) {
  if (Number.isNaN(value)) return "";

  try {
    return format(convertToMilliseconds(value), standardDateFormat);
  } catch (e) {
    console.error(e)
    return "";
  }
}

export function listToOptions(options: Array<any>, originalObject: any = null, fontSize?: string) {
  return options.map((key: any) => (
    <MenuItem key={key} value={originalObject ? originalObject[key] : key} style={fontSize ? {fontSize} : {}}>
      {originalObject ? originalObject[key] : key}
    </MenuItem>
  ));
}

export function formatPath(path: string, parentPath?: string) {
  const newPath = path.replace(' ', '_').toLowerCase();
  if (parentPath) {
    return `${parentPath}.${newPath}`
  }
  return newPath
}

export function tableDataFunction(
  assetFilters: Asset[],
  query: any,
  queryFunc: Function,
  parseResults: Function,
  extraQueryParams: object = {},
): Promise<any> {
  let params: { [k: string]: any } = {
    asset__path__descendant_of_included: assetFilters.map(asset => asset.path).join(),
    limit: query.pageSize,
    offset: query.page * query.pageSize,
    ...extraQueryParams
  };
  query.filters.forEach((filter: any) => {
    if (filter.value !== null){
      if (typeof(filter.value) === 'object' && !Array.isArray(filter.value)) {
            params = {...params, ...filter.value};
      } else if (Array.isArray(filter.value)){
        if (filter.value.length){
          params[`${filter.column.field}__in`] = filter.value;
        }
      } else {
        params[filter.column.field] = filter.value;
      }
    }
  });
  if (query.orderBy) {
    params.ordering = `${query.orderDirection === 'desc' ? '-' : ''}${query.orderBy.field}`
  }
  if (query.search) {
    params.search = query.search.replace(' ', '_')
  }
  return new Promise((resolve: any, reject: any) => {
    queryFunc(params).then((result: any) => {
      resolve({
        data: parseResults(result.results),
        page: query.page,
        totalCount: result.count
      })
    })
  });
}

export function convertToMilliseconds(value: number) {
  if (value/Math.pow(10, 12) < 1) {
    return value * 1000
  }
  return value
}

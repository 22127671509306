import React, { useState, useEffect } from 'react';
import {
  Paper,
  TableContainer,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { TableIcons } from '../../components';
import MaterialTable, { MTableToolbar } from "material-table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { tableDataFunction } from "../../util/helpers";
import { Asset } from "../../models";

const useStyles = makeStyles(theme => ({
  table: {
    '& td': theme.table.cellPadding
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.table.header
  },
  headerTitle: {
    marginRight: theme.spacing(2),
    ...theme.fontStyling.title
  },
  filterSwitch: {
    display: "flex",
    alignItems: "center",
    marginLeft: 'auto'
  }
}));

interface Props {
  onRowClick?: any,
  assetFilters: Asset[],
  search: boolean,
  paging: boolean,
  rowSelected?: number,
  title: string | null,
  columns: object[],
  queryFunction: Function,
  parseFunction: Function,
  rowStyle?: any,
  extraQueryParams?: any,
  uploadComponent?: JSX.Element
}

function TableWrapper(props: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    onRowClick,
    assetFilters,
    search,
    paging,
    title,
    columns,
    queryFunction,
    parseFunction,
    rowStyle,
    extraQueryParams,
    uploadComponent
  } = props;

  const tableRef: any = React.createRef();

  const [filter, setFilter] = useState<boolean>(false)

  useEffect(() => {
    tableRef?.current && tableRef.current.onQueryChange();
  },
    // eslint-disable-next-line
    [assetFilters]
  );

  return (
    <TableContainer component={Paper} className={classes.table}>
      <MaterialTable
        tableRef={tableRef}
        icons={TableIcons}
        components={{
          Toolbar: props => {
            // Make a copy of props so we can hide the default title and show our own
            // This let's us use our own Title while keeping the search
            const propsCopy = { ...props };
            // Hide default title
            propsCopy.showTitle = false;
            if (!title) {
              return null;
            }
            return (
              <span className={classes.headerBox}>
                <span className={classes.headerTitle}>
                  {title}
                </span>

                {uploadComponent}

                <FormControlLabel
                  className={classes.filterSwitch}
                  control={
                    <Switch
                      color="primary"
                      checked={filter}
                      onChange={() => setFilter(!filter)}
                    />
                  }
                  label={filter ? "Hide Filters" : "Show Filters"}
                />

                <MTableToolbar {...propsCopy} />
              </span>
            );
          },
        }}
        columns={columns}
        data={query =>
          tableDataFunction(assetFilters, query, queryFunction, parseFunction, extraQueryParams)
        }
        options={{
          filtering: filter,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20],
          emptyRowsWhenPaging: false,
          headerStyle: theme.table.header,
          search,
          paging,
          rowStyle: rowData => {
            let style = {
              ...theme.fontStyling.table,
            };
            if (rowStyle) {
              style = {
                ...style,
                ...rowStyle(rowData)
              }
            }
            return style
          },
          debounceInterval: 1000,
        }}
        onRowClick={onRowClick}
      />
    </TableContainer>
  );
}

export default TableWrapper;

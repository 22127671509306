import React from 'react';
import ReactDOM from 'react-dom';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';
import {combineReducers} from 'redux';

import AppWrapper from './appWrapper';

import {Auth0Provider} from './auth';

import appSlice from './appSlice';
import AlertSlice from "./pages/alerts/AlertsSlice";
import MaintenanceSlice from "./pages/maintenance/MaintenanceSlice";
import ProductionSlice from "./pages/production/ProductionSlice";
import DataSlice from "./pages/data/DataSlice";
import ModelsSlice from "./pages/model/ModelsSlice";


const reducer = combineReducers({
  app: appSlice,
  alerts: AlertSlice,
  maintenance: MaintenanceSlice,
  production: ProductionSlice,
  data: DataSlice,
  models: ModelsSlice
});

const store = configureStore({ reducer });

ReactDOM.render(
  <Auth0Provider>
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);


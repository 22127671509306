import {Model} from './Model'
import {Asset} from "./Asset";

export enum MODEL_GROUP_STATUS {
    RUNNING = 'Running',
    FAILING = 'Failing',
    DISABLED = 'Disabled',
}

export enum MODEL_STATUS_COLORS {
  RUNNING = 'success',
  FAILING = 'error',
  DISABLED = 'info',
}

export enum DEPARTMENTS {
  MAINTENANCE = 'Maintenance',
  PRODUCTION = 'Production'
}

interface MetaData {
  auto_approve: boolean,
  muted: boolean,
  input_rate: number,
  data_version: string,
  num_clusters: any,
  muted_clusters: Array<number>
}

export interface TempModelGroup {
    id?: number,
    name: string,
    model_types?: Array<Model>,
    variable_uids: Array<string>,

    run_live?: boolean,
    auto_approve_alarms?: boolean,
    input_rate?: number,
    meta_data?: MetaData,
    run?: boolean | null,
    run_job?: string,
    run_rate_seconds?: number,
    last_run_job_id?: string,
    last_run_schedule_timestamp?: number,
    last_run_timestamp?: number,
    last_run_succeeded?: boolean | null,
    last_train_job_id?: string,
    last_train_schedule_timestamp?: number,
    last_train_timestamp?: number,
    last_train_succeeded?: boolean | null,
    assets?: Asset[],
    department?: string,
}

export interface ModelGroup extends TempModelGroup{
    id: number,
    model_types: Array<Model>,

    hierarchy_branch: Array<string>,
    run_live: boolean,
    auto_approve_alarms: boolean,
    input_rate: number,

    equipment: number,
    variable_uids: Array<string>,
    meta_data: MetaData,
    run: boolean | null,
    run_job: string,
    run_rate_seconds: number,
    last_run_job_id: string,
    last_run_schedule_timestamp: number,
    last_run_timestamp: number,
    last_run_succeeded: boolean | null,
    last_train_job_id: string,
    last_train_schedule_timestamp: number,
    last_train_timestamp: number,
    last_train_succeeded: boolean | null,
    assets: Asset[],
    department: keyof typeof DEPARTMENTS,
}

export interface ModelGroupStatus extends TempModelGroup{
    id: number,
    status: keyof typeof MODEL_GROUP_STATUS,
    run_rate_seconds: number,
    last_run_schedule_timestamp: number,
    last_run_timestamp: number,
    last_run_succeeded: boolean,
    last_train_schedule_timestamp: number,
    last_train_timestamp: number,
    last_train_succeeded: boolean,
}

export type ModelGroupAggregates = {
    [key in keyof typeof MODEL_GROUP_STATUS]: number
}

import React, { useEffect, useState } from 'react';
import { EditCellColumnDef } from "material-table";
import {Box, IconButton} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import {getUnixTime} from "date-fns";
import {Cancel} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  pickersContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    justifyContent: "space-between",
  },
  picker: {
    marginBottom: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 90,
  },
  dateDisplay: {
    display: 'inline-block',
    verticalAlign:'bottom',
    paddingBottom: '0.25rem'
  }
}));

interface Props {
  columnDef: EditCellColumnDef,
  onFilterChanged: Function
}

function DateRangeFilter(props: Props) {
  const classes = useStyles();

  const {
    columnDef,
    onFilterChanged
  } = props;

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  useEffect(() => {
    if (fromDate || toDate) {
      const filter: any = {};
      if (fromDate) {
        filter[`${columnDef.field}__gte`] = getUnixTime(fromDate.setHours(0, 0, 0, 0));
      }
      if (toDate) {
        filter[`${columnDef.field}__lte`] = getUnixTime(toDate.setHours(23, 59, 59, 0));
      }
      onFilterChanged(columnDef.tableData.id, filter)
    }
  }, [
    fromDate, toDate,
    columnDef.field, columnDef.tableData.id, onFilterChanged
  ]);

  // TODO: replace this component with a DateRangePicker when it becomes stable/available:
  // https://next.material-ui-pickers.dev/demo/daterangepicker
  return (
    <div style={{flexDirection: "row"}}>
      <Box className={classes.pickersContainer}>
        <Box className={classes.picker}>
          <DatePicker
            onChange={(event: any) => event && setFromDate(event)}
            value={fromDate}
            autoOk label='From'
            allowKeyboardControl
            InputProps={{
              endAdornment:
                fromDate
                  ? <IconButton onClick={() => setFromDate(null)}>
                      <Cancel />
                    </IconButton>
                  : null
            }}
          />
        </Box>
        <Box className={classes.picker}>
          <DatePicker
            value={toDate}
            onChange={(event: any) => event && setToDate(event)}
            autoOk label='To'
            allowKeyboardControl
            InputProps={{
              endAdornment:
                toDate
                  ? <IconButton onClick={() => setToDate(null)}>
                      <Cancel />
                    </IconButton>
                  : null
            }}
          />
        </Box>
      </Box>
    </div>
  )
}

export default DateRangeFilter;

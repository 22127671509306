import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import {Asset, DEPARTMENTS, TempModelGroup, Variable} from "../../models";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import {listToOptions} from "../../util/helpers";
import {queryVariables} from "../../api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  multiselect: {
    maxWidth: '40rem'
  }
}));

interface Props {
  show: boolean,
  submit: (modelGroup: TempModelGroup) => void,
  cancel: () => void,
  assets: Array<Asset>
}

function ModelForm(props: Props) {
  const classes = useStyles();

  const {
    show,
    submit,
    cancel,
    assets
  } = props;

  const [variables, setVariables] = useState<Array<Variable>>([]);
  const [loadingVars, setLoadingVars] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("")
  let filterCooldown: ReturnType<typeof setTimeout>;

  const [modelGroup, setModelGroup] = useState({
    name: '',
    variable_uids: [],
    assets: [],
    department: ''
  });
  const [nameError, setNameError] = useState(false);
  const [variableError, setVariableError] = useState(false);
  const [assetError, setAssetError] = useState(false);
  const [departmentError, setDepartmentError] = useState(false);

  return (
    <Modal
      open={show}
      className={classes.modal}
    >
      <Fade in={show}>
        <Paper className={classes.modalBody}>
          <Typography variant='h4'>
            Create a Machine Learning Model
            <IconButton onClick={(event) => cancel()}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <FormControl
            required
          >
            <TextField
              label="Nickname"
              error={nameError}
              helperText={
                nameError ? 'Select a nickname.' : ''
              }
              onChange={(event) => {
                setModelGroup({
                  ...modelGroup,
                  name: event.target.value
                });
                setNameError(false);
              }}
            />
          </FormControl>
          <FormControl
            required
          >
            <Autocomplete
              noOptionsText={searchValue.length > 0 ? `No sensor variables match "${searchValue}".` : "Start typing to search variables."}
              multiple disableCloseOnSelect
              className={classes.multiselect}
              options={variables}
              getOptionLabel={getOptionLabel}
              renderOption={(c: any) => `${c.name} (${c.uid})`}
              onChange={handleVariablesChange}
              onClose={() => setSearchValue("")}
              loading={loadingVars}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: '100%' }}
                  helperText={
                    'Select variables you\'d like this model to monitor. Start typing to search variables.'
                  }
                  label="Monitored Variables"
                  margin="normal"
                  variant="outlined"
                  error={variableError}
                  onChange={filterOptions}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingVars ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Autocomplete
              noOptionsText={searchValue.length > 0 ? `No model locations match "${searchValue}".` : "Start typing to search locations."}
              multiple disableCloseOnSelect
              className={classes.multiselect}
              options={assets ? assets.filter(asset => !asset.model_input) : []}
              getOptionLabel={getOptionLabel}
              renderOption={(c: any) => c.path_title}
              onChange={handleAssetsChange}
              onClose={() => setSearchValue("")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: '100%' }}
                  helperText={
                    'Select the locations for this model. Start typing to search locations.'
                  }
                  label="Model Locations"
                  margin="normal"
                  variant="outlined"
                  error={assetError}
                  onChange={filterOptions}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingVars ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            error={departmentError}
            variant="outlined"
            className={classes.multiselect}
          >
            <InputLabel id="department">Department</InputLabel>
            <Select
              labelId="department"
              onChange={handleDepartmentChange}
              label="department"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                }
              }}
            >
              {listToOptions(Object.values(DEPARTMENTS))}
            </Select>
            <FormHelperText>Select the model department.</FormHelperText>
          </FormControl>
          <Button
            variant="contained"
            component="span"
            size="large"
            color="primary"
            onClick={() => {
              if (!modelGroup.name.length) setNameError(true);
              if (!modelGroup.variable_uids.length) setVariableError(true);
              if (!modelGroup.assets.length) setAssetError(true);
              if (!modelGroup.department.length) setDepartmentError(true);
              submit(modelGroup)
            }}
          >
            Create
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );

  function filterOptions(event: any) {
    if (filterCooldown) {
      clearTimeout(filterCooldown);
    }
    const value = event.target.value;
    if (value !== '') {
      filterCooldown = setTimeout(() => {
        setSearchValue(value);
        setLoadingVars(true);
        queryVariables({
          name__iregex: value
        }).then(vars => {
          setVariables(vars);
          setLoadingVars(false);
        });
      }, 500);
    }
  }

  function getOptionLabel(option: any) {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    if (option.path_title) {
      return option.path_title
    }
    return option.name;
  }

  function handleVariablesChange(event: any, value: any) {
    setModelGroup({
      ...modelGroup,
      variable_uids: value.map((v: any) => v.uid)
    });
    setVariableError(false);
  }

  function handleAssetsChange(event: any, value: any) {
    setModelGroup({
      ...modelGroup,
      assets: value
    });
    setAssetError(false);
  }

  function handleDepartmentChange(event: any, value: any) {
    if (value.props && value.props.value){
      setModelGroup({
        ...modelGroup,
        department: value.props.value.toUpperCase()
      })
      setDepartmentError(false)
    }
  }

}

export default ModelForm;

import React from 'react';
import LineGraph from './lineGraph';
import {isLoading, mapSeriesData} from '../../util/helpers';
import {Anomaly, ModelOutput, TimeRange} from "../../models";
import {makeStyles} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  scoringGraph: {
  },
}));

interface Props {
  title?: string
  seriesData?: Array<ModelOutput>,
  anomalies?: Array<Anomaly>,
  // thresholds: [],
  timeRange: TimeRange,
  updateTimeRange: Function
}

function ScoringGraph(props: Props) {
  const classes = useStyles();
  const MAX_CHI_VALUE = 1.0;
  const {
    title = '',
    seriesData,
    anomalies = [],
    // thresholds = {},
    timeRange,
    updateTimeRange
  } = props;

  // Loading state
  if (isLoading(seriesData)) {
    return <Skeleton variant='rect' animation='wave' style={{
      width: '100%'
    }}/>
  }

  // No data
  if (!seriesData || !seriesData.length) {
    return<div className={classes.scoringGraph}>
      No corresponding {title} data
    </div>;
  }

  // TODO: Re-implement thresholds
  // let breachPoints = seriesData[0].data
  //   .filter((datapoint, index, datapoints) => {
  //     const priorY = index > 0 ? datapoints[index - 1].y : null;
  //
  //     if (priorY) {
  //       return datapoint.y >= thresholds.default && priorY < thresholds.default;
  //     }
  //     return datapoint.y >= thresholds.default;
  //   })
  //   .map(datapoint => {
  //     return datapoint.id;
  //   });

  // if (thresholds.secondary) {
  //   if (thresholds.secondary > MAX_CHI_VALUE) {
  //     thresholds.secondary = MAX_CHI_VALUE;
  //   }
  //   const secondaryBreachPoints = seriesData[0].data
  //     .filter((datapoint, index, datapoints) => {
  //       return (
  //         datapoint.y >= thresholds.secondary && datapoints[index - 1].y < thresholds.secondary
  //       );
  //     })
  //     .map(datapoint => {
  //       return datapoint.id;
  //     });
  //
  //   breachPoints = breachPoints.concat(secondaryBreachPoints);
  // }

  const mappedData = mapSeriesData(seriesData);

  return (
    <div className={classes.scoringGraph}>
      <LineGraph
        title={title}
        data={mappedData}
        // thresholds={thresholds}
        // markedPoints={breachPoints}
        maxValue={MAX_CHI_VALUE}
        showLegend
        size="medium"
        // yLabelTitle="Score"
        timeRange={timeRange}
        updateTimeRange={updateTimeRange}
        anomalies={anomalies}
      />
    </div>
  );
}

export default ScoringGraph;

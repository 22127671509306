import React from 'react';
import {
  ALERT_STATUS,
  Alert,
  Asset,
  DEPARTMENTS,
  STATUS_COLORS,
} from '../../../models';
import {formatDistance} from 'date-fns';
import {useTheme} from "@material-ui/core/styles";
import _ from 'lodash';
import {ProductionRun} from "../../../models";
import {queryAlertsPaginated} from "../../../api";
import {startCase} from 'lodash';
import TableWrapper from "../../../components/tables/tableWrapper";
import StatusChip from "../../../components/ui/statusChip";
import {formatTimestampToString} from "../../../util/helpers";
import {DateRangeFilter} from "../../../components";
import RangeFilter from "../../../components/tables/rangeFilter";


interface Props {
  onRowClick: Function,
  assetFilters: Asset[],
  search: boolean,
  paging: boolean,
  rowSelected: number,
  alertType: string
}

function AlertTable(props: Props) {
  const theme = useTheme();
  const {
    onRowClick,
    assetFilters,
    search,
    paging,
    rowSelected,
    alertType
  } = props;

  const title = `${alertType === "Alerts" ? "All": alertType} Alerts`;
  const extraQueryParams = alertType === "Alerts" ? {} : {model_input__department: alertType.toUpperCase()};

  console.log(rowSelected)

  const columns = [
    {
      title: 'Status',
      field: 'status',
      lookup: ALERT_STATUS,
      render: (rowData:any) =>
        <div>
          <StatusChip
            status={ALERT_STATUS[rowData.status as keyof typeof ALERT_STATUS]}
            width={10}
            color={STATUS_COLORS[rowData.status as keyof typeof STATUS_COLORS]}
            size={'small'}
          />
        </div>
    },
    {
      title: 'Alert#',
      field: 'id',
      width: `5%`,
    },
    {
      title: 'Start Datetime',
      field: 'start_timestamp',
      type: 'datetime',
      width: `9%`,
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: 'End Datetime',
      field: 'end_timestamp',
      type: 'datetime',
      emptyValue: 'N/A',
      width: `9%`,
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: 'Duration',
      field: 'time_open',
      filterComponent: (props: any) => <RangeFilter {...props}/>
    },
    {
      title: 'Department',
      field: 'model_input__department',
      lookup: DEPARTMENTS,
    },
    {
      title: 'Location',
      field: 'model_input__asset__path',
      width: `19%`,
      filtering: false,
    },
    {
      title: 'Batch',
      field: 'batch',
      width: `11%`,
      emptyValue: 'N/A',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Progress',
      field: 'progress',
      emptyValue: 'N/A',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Batch End Datetime',
      field: 'batchEndDatetime',
      type: 'datetime',
      emptyValue: 'N/A',
      width: `10%`,
      sorting: false,
      filtering: false,
    },
    {
      title: 'Product',
      field: 'product',
      emptyValue: 'N/A',
      width: `10%`,
      sorting: false,
      filtering: false,
    },
  ];

  return <TableWrapper
    assetFilters={assetFilters}
    search={search}
    paging={paging}
    title={title}
    columns={columns}
    queryFunction={queryAlertsPaginated}
    parseFunction={parseAlertResults}
    onRowClick={onRowClickTable}
    rowStyle={rowStyle}
    extraQueryParams={extraQueryParams}
  />;


  function parseAlertResults(alerts: Alert[]) {
    return alerts.map(alert => {
      const production : any = {
        batch: null,
        progress: null,
        batchEndDatetime: null,
        product: null
      };
      if (alert.production.length) {
        if (alert.production.length <= 2) {
          production.batch = alert.production.map(
            (production: ProductionRun) => startCase(production.batch.replace('_', ' '))
          ).join(', ');
          production.product = alert.production.map(
            (production: ProductionRun) => startCase(production.product.replace('_', ' '))
          ).join(', ');
        } else {
          production.batch = alert.production.length + ' batches';
          production.product = alert.production.length + ' products';
        }
        if (alert.production.find((production: ProductionRun) => !production.end_timestamp)) {
          production.progress = 'In Progress';
        } else {
          production.progress = 'Completed';
          production.batchEndDatetime = formatTimestampToString(Math.max(
            ...alert.production.map((production: ProductionRun) => production.end_timestamp || 0)
          ));
        }
      }
      return {
        id: alert.id,
        status: alert.status,
        start_timestamp: formatTimestampToString(alert.start_timestamp),
        end_timestamp: alert.end_timestamp
                      ? formatTimestampToString(alert.end_timestamp)
                      : null,
        time_open: _.startCase(_.toLower(formatDistance(0, alert.time_open * 1000))),
        model_input__department: alert.model_group.department,
        model_input__asset__path: alert.model_group.assets.map((asset: Asset) => asset.path_title).join(', '),
        batch: production.batch,
        progress: production.progress,
        batchEndDatetime: production.batchEndDatetime,
        product: production.product,
      }
    });
  }

  function onRowClickTable (event: any, rowData: any, toggleDetailPanel: any) {
    if (rowData && rowData.id && onRowClick){
      onRowClick(rowData.id)
    }
  }

  function rowStyle (rowData:any) {
    if (rowSelected) {
      return (
        {
          backgroundColor: rowData.id === rowSelected ? theme.palette.background.default : '',
          display: rowData.id !== rowSelected ? 'none' : ''
        }
      )
    }
    return (
      {
        backgroundColor: '',
      }
    )
  }
}

export default AlertTable;

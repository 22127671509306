import React from 'react';
import {fromUnixTime} from 'date-fns';
import {queryMaintenancePaginated} from "../../../api";
import {Asset, MaintenanceData} from "../../../models";
import TableWrapper from "../../../components/tables/tableWrapper";
import {formatDuration} from "../../../util/helpers";

interface Props {
  assetFilters: Asset[],
  search: boolean,
  
  paging: boolean,
  alertId?: number,
  timestamp?: number
}

function MaintenanceTable(props: Props) {
  const {
    assetFilters,
    search,
    paging,
    alertId,
    timestamp,
  } = props;

  let extraQueryParams = {};
  if (alertId && timestamp){
    extraQueryParams = {
      similarity__gte: 0,
      timestamp,
      alert_id: alertId
    }
  }

  const title = 'Maintenance Data';
  let columns = [
    {
      title: 'ID',
      field: 'external_id',
    },
    {
      title: 'Similarity Rank',
      field: 'rank',
      emptyValue: 'N/A',
      hidden: !(alertId && timestamp)
    },
    {
      title: 'Lead Time',
      field: 'time_prior',
      emptyValue: 'N/A',
      hidden: !(alertId && timestamp)
    },
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Location',
      field: 'asset__path',
    },
    {
      title: 'Created Date',
      field: 'create_timestamp',
      type: 'datetime',
    },
    {
      title: 'Start Date',
      field: 'start_timestamp',
      type: 'datetime',
      emptyValue: 'N/A',
    },
    {
      title: 'End Date',
      field: 'end_timestamp',
      type: 'datetime',
      emptyValue: 'N/A',
    },
    {
      title: 'Closed Date',
      field: 'close_timestamp',
      type: 'datetime',
      emptyValue: 'N/A',
    },
  ];

  return <TableWrapper
    assetFilters={assetFilters}
    search={search}
    
    paging={paging}
    title={title}
    columns={columns}
    queryFunction={queryMaintenancePaginated}
    parseFunction={parseMaintenanceResults}
    extraQueryParams={extraQueryParams}
  />;

  function parseMaintenanceResults(results: MaintenanceData[]){
    return results.map(maintenanceEvent => {
      return {
        external_id: maintenanceEvent.external_id,
        title: maintenanceEvent.title,
        asset__path: maintenanceEvent.asset.path_title,
        create_timestamp: fromUnixTime(maintenanceEvent.create_timestamp),
        start_timestamp: maintenanceEvent.start_timestamp ? fromUnixTime(maintenanceEvent.start_timestamp) : null,
        end_timestamp: maintenanceEvent.end_timestamp ? fromUnixTime(maintenanceEvent.end_timestamp) : null,
        close_timestamp: maintenanceEvent.close_timestamp ? fromUnixTime(maintenanceEvent.close_timestamp) : null,
        rank: maintenanceEvent?.rank,
        time_prior: maintenanceEvent.time_prior ? formatDuration(maintenanceEvent.time_prior) : null
      }
    })
  }
}

export default MaintenanceTable;

import React from 'react';
import {
  ALERT_STATUS,
  MODEL_GROUP_STATUS,
  MODEL_STATUS_COLORS,
  STATUS_COLORS,
  VARIABLE_STATUS,
  VARIABLE_STATUS_COLORS
} from "../../models";
import {Chip, useTheme} from "@material-ui/core";

interface Props {
  color: STATUS_COLORS | VARIABLE_STATUS_COLORS | MODEL_STATUS_COLORS,
  status: ALERT_STATUS | VARIABLE_STATUS | MODEL_GROUP_STATUS,
  width: number,
  size: 'small' | 'medium'
}

function StatusChip(props: Props) {
  const theme = useTheme();
  const {
    color,
    status,
    width,
    size } = props;
  return (
    <Chip
      size={size}
      label={status}
      style={
        {
          backgroundColor: theme.palette[color].main,
          width: theme.spacing(width)
        }
      }
    />)
}

export default StatusChip;

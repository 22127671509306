import React, {useState} from 'react';
import {queryDataStatusPaginated} from "../../../api";
import {
  Asset,
  VARIABLE_STATUS,
  VARIABLE_STATUS_COLORS,
  VariableStatus
} from "../../../models";
import {formatTimestampToString, formatDuration} from "../../../util/helpers";
import TableWrapper from "../../../components/tables/tableWrapper";
import StatusChip from "../../../components/ui/statusChip";
import {Button} from "@material-ui/core";
import {DateRangeFilter, RangeFilter, RawDataForm} from "../../../components";

interface Props {
  assetFilters: Asset[],
  search: boolean,
  paging: boolean,
}

function DataTable(props: Props) {
  const {
    assetFilters,
    search,
    paging,
  } = props;

  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const title = 'Variable Data';
  const columns = [
      {
        title: 'Status',
        field: 'status',
        render: (rowData:VariableStatus) =>
        <div>
          <StatusChip
            status={VARIABLE_STATUS[rowData.status]}
            width={13}
            color={VARIABLE_STATUS_COLORS[rowData.status]}
            size={'small'}
          />
        </div>
      },
      {
        title: 'Variable Name',
        field: 'variable__name',
      },
      {
        title: 'Last Updated Timestamp',
        field: 'last_timestamp',
        type: 'datetime',
        filterComponent: (props: any) => <DateRangeFilter {...props}/>
      },
      {
        title: 'Time Since Last Update',
        field: 'update_interval_cur',
        filterComponent:  (props: any) => <RangeFilter {...props}/>
      },
      {
        title: 'Update Interval - Avg',
        field: 'update_interval_avg',
        filterComponent:  (props: any) => <RangeFilter {...props}/>
      },
      {
        title: 'Update Interval - Std Dev',
        field: 'update_interval_std',
        filterComponent:  (props: any) => <RangeFilter {...props}/>
      },
    ];

  const extraQueryParams = {};

  return <TableWrapper
    assetFilters={assetFilters}
    search={search}
    paging={paging}
    title={title}
    columns={columns}
    queryFunction={queryDataStatusPaginated}
    parseFunction={parseDataResults}
    extraQueryParams={extraQueryParams}
    uploadComponent={<>

      <Button
        variant='contained'
        color='primary'
        component='label'
      >
        Upload Raw Data
        <input
          type='file'
          style={{display: "none"}}
          onChange={(event) => event.target.files?.[0] && setUploadFile(event.target.files[0])}
        />
      </Button>
      <RawDataForm
        file={uploadFile}
        closeUpload={() => setUploadFile(null)}
      />
    </>}
  />;

  function parseDataResults(results: VariableStatus[]){
    return results.map(dataStream => ({
      status: dataStream.status,
      variable__name: dataStream.variable.name,
      last_timestamp: formatTimestampToString(dataStream.last_timestamp),
      update_interval_cur: formatDuration(Math.round(dataStream.update_interval_cur)),
      update_interval_avg: formatDuration(Math.round(dataStream.update_interval_avg)),
      update_interval_std: formatDuration(Math.round(dataStream.update_interval_std))
    }))
  }
}

export default DataTable;

import {createSlice} from '@reduxjs/toolkit';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataState {
}

const initialState: DataState = {
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
  }
});


// eslint-disable-next-line no-empty-pattern
export const {  } = dataSlice.actions;

export default dataSlice.reducer;
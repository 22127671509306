import React from 'react';
import LineGraph from './lineGraph';
import {isLoading, mapSeriesData} from '../../util/helpers';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/core/';
import {Anomaly, TimeRange, VariableData} from '../../models';

interface Props {
  data?: Array<VariableData>,
  anomalies? : Array<Anomaly>,
  timeRange: TimeRange
  updateTimeRange: Function,
  highchartsCallback?: any
}

const useStyles = makeStyles(theme => ({
  variableGraph: {
  },
}));

function VariablesGraph(props: Props) {
  const classes = useStyles();
  const {
    data,
    anomalies= [],
    timeRange,
    updateTimeRange,
    highchartsCallback
  } = props;

  // Loading state
  if (isLoading(data)) {
    return <Skeleton variant='rect' animation='wave' style={{
      width: '100%'
    }}/>
  }

  // No data
  if (!data || !data.length) {
    return <div id="variables" className={classes.variableGraph}>
      No corresponding data
    </div>;
  }
  const mappedData = mapSeriesData(data);
  return (
    <div id="variables" className={classes.variableGraph}>
      <LineGraph
        title="Raw Sensor Data"
        data={mappedData}
        size="medium"
        timeRange={timeRange}
        updateTimeRange={updateTimeRange}
        anomalies={anomalies}
        showLegend={false}
        highchartsCallback={highchartsCallback}
      />
    </div>
  );
}


export default VariablesGraph;

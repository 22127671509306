import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {
  Asset, MODEL_GROUP_STATUS, MODEL_STATUS_COLORS, ModelGroupStatus,
} from "../../../models";
import {formatTimestampToString} from "../../../util/helpers";
import {queryModelGroupStatusPaginated} from "../../../api";
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";
import TableWrapper from "../../../components/tables/tableWrapper";
import StatusChip from "../../../components/ui/statusChip";
import {DateRangeFilter, RangeFilter} from "../../../components";
import {MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  warning: {
    color: theme.palette.error.main,
    marginBottom: '-0.4rem',
  },
}));

interface Props {
  assetFilters: Asset[],
  search: boolean,
  
  paging: boolean,
}

function ModelTable(props: Props) {
  const classes = useStyles();
  const {
    assetFilters,
    search,
    
    paging,
  } = props;

  const title = 'Model Data';
  const columns = [
    {
      title: "Status",
      field: "status",
      render: (rowData: ModelGroupStatus) =>
        <div>
          <StatusChip
            status={MODEL_GROUP_STATUS[rowData.status]}
            width={10}
            color={MODEL_STATUS_COLORS[rowData.status]}
            size={'small'}
          />
        </div>,
    },
    {
      title: "Model ID",
      field: "id"
    },
    {
      title: "Model Name",
      field: "name"
    },
    {
      title: "Run Rate",
      field: "run_rate_seconds",
      filterComponent:  (props: any) => <RangeFilter {...props}/>
    },
    {
      title: "Last Run Scheduled",
      field: "last_run_schedule_timestamp",
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: "Last Run Date",
      field: "last_run_timestamp",
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: "Last Run Status",
      field: "last_run_succeeded",
      filterComponent: (props: any) => successFilter(props)
    },
    {
      title: "Last Train Scheduled",
      field: "last_train_schedule_timestamp",
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: "Last Train Date",
      field: "last_train_timestamp",
      filterComponent: (props: any) => <DateRangeFilter {...props}/>
    },
    {
      title: "Last Train Status",
      field: "last_train_succeeded",
      filterComponent: (props: any) => successFilter(props)
    },
  ];

  const extraQueryParams = {};

  return <TableWrapper
    assetFilters={assetFilters}
    search={search}
    
    paging={paging}
    title={title}
    columns={columns}
    queryFunction={queryModelGroupStatusPaginated}
    parseFunction={parseModelResults}
    extraQueryParams={extraQueryParams}
  />;


  function parseModelResults(results: ModelGroupStatus[]){
    return results.map(model => {
      return {
        id: model.id,
        status: model.status,
        name: model.name,
        run_rate_seconds: `${Math.round(model.run_rate_seconds/60)} minutes`,
        last_run_schedule_timestamp: handleTimestamp(model.last_run_schedule_timestamp),
        last_run_timestamp: handleTimestamp(model.last_run_timestamp),
        last_run_succeeded: handleBoolean(model.last_run_succeeded),
        last_train_schedule_timestamp: handleTimestamp(model.last_train_schedule_timestamp),
        last_train_timestamp: handleTimestamp(model.last_train_timestamp),
        last_train_succeeded: handleBoolean(model.last_train_succeeded)
      }
    })
  }

  function handleTimestamp(value: number) {
    if (value === null) {
      return 'No Data Available'
    }
    return formatTimestampToString(value)
  }

  function handleBoolean(value: boolean) {
    if (value === null) {
      return 'No Data Available'
    } else if (value) {
      return 'Successful'
    }
    return <span>Failed&nbsp;<WarningIcon className={classes.warning} /></span>
  }

  function successFilter(props: any) {
    return (
      <Select
        style={{width: '100%'}}
        onChange={(event) => {
          if (event.target.value === 'null') {
            const filter: any = {};
            filter[`${props.columnDef.field}__isnull`] = true;
            props.onFilterChanged(
              props.columnDef.tableData.id,
              filter
            )
          } else {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            )
          }
        }}
      >
        <MenuItem>&nbsp;</MenuItem>
        <MenuItem value={'null'}>
          No Data Available
        </MenuItem>
        <MenuItem value={'true'}>
          Successful
        </MenuItem>
        <MenuItem value={'false'}>
          Failed
        </MenuItem>
      </Select>
    )
  }
}

export default ModelTable;

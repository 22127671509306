import React, {useState} from 'react';
import {
  AlertStatusHistoryGraph,
  FoldableSection,
  MaintenanceGraph,
  VariablesGraph,
  ScoringGraph,
  SpectralGraphs,
  TimeRangeSelector,
  AlertForm
} from '../../../components';
import {
  patchAlert,
} from '../../../api';
import {
  Alert,
  Anomaly,
} from '../../../models';
import {Box, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {isEmpty} from 'lodash';
import {AlertsState} from "../AlertsSlice";
import HighchartsLegend from "../../../components/ui/highchartsLegend";
import MaintenanceTable from "../../maintenance/components/maintenanceTable";


const useStyles = makeStyles(theme => ({
  content: {
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
    '& > .MuiGrid-item': {
      maxHeight: '100%',
      overflow: 'auto',
    },
    padding: theme.spacing(1)
  },
  breadcrumb: {
    margin: `${theme.spacing(1)}px 0`,
  },
}));

interface Props {
  alertsState: AlertsState,
  updateTimeRange: Function,
  setAlert: Function,
}

function AlertDetail(props: Props) {
  const {
    alertsState,
    updateTimeRange,
    setAlert
  } = props;
  const classes = useStyles();

  const {
    alert,
    waterfallData,
    variablesData,
    flaggerScores,
    decisionScores,
    detectionScores,
    maintenanceData,
    timeRange,
    workOrderTypes,
    statusHistory
  } = alertsState;

  const [chartSeries, setChartSeries] = useState([]);
  let alertAnomalies: Array<Anomaly> = [];
  if (statusHistory) {
    alertAnomalies = statusHistory.map(status => {
      const start = status.timestamp * 1000;
      return {
        status: status.status,
        from: start,
        to: start + 1,
      }
    });
  }

  return (
    <Grid container spacing={1} className={classes.content}>
      <Grid item xs={12}>
        <FoldableSection title="Alert Information" openDefault={true}>
          <Grid container spacing={1} className={classes.content}>
            <Grid item xs={9}>
              {statusHistory && (
                <AlertStatusHistoryGraph
                  timeRange={timeRange}
                  updateTimeRange={updateTimeRange}
                  statusHistory={statusHistory}
                />
              )}
              <Box m={1}/>
              <VariablesGraph
                data={variablesData}
                anomalies={alertAnomalies}
                timeRange={timeRange}
                updateTimeRange={updateTimeRange}
                highchartsCallback={highchartsCallback}
              />
              <Box m={1}/>
              <MaintenanceGraph
                maintenanceData={maintenanceData}
                timeRange={timeRange}
                updateTimeRange={updateTimeRange}
                workOrderTypes={workOrderTypes}
              />
              <Box m={1}/>
              <TimeRangeSelector
                timeRange={timeRange}
                updateTimeRange={updateTimeRange}
              />
            </Grid>
            <Grid item xs={3}>
              {
                alert &&
                <AlertForm
                  alert={alert}
                  onSubmit={saveAlert}
                />
              }
              <Box m={1}/>
              { alert &&
                <HighchartsLegend
                  items={chartSeries}
                />
              }
            </Grid>
          </Grid>
        </FoldableSection>

        <FoldableSection title={'Maintenance Events'}>
          { alert &&
            <MaintenanceTable
              assetFilters={alert.model_group.assets}
              search
              
              paging
              alertId={alert.id}
              timestamp={alert.last_timestamp}
            />
          }
        </FoldableSection>

        {!isEmpty(waterfallData) && (<FoldableSection title="Spectral Data">
          <SpectralGraphs waterfallData={waterfallData}/>
        </FoldableSection>)}

        <FoldableSection title="Data Science">
          <ScoringGraph
            title={'Flagger'}
            seriesData={flaggerScores}
            anomalies={alertAnomalies}
            timeRange={timeRange}
            updateTimeRange={updateTimeRange}
          />
          <Box m={1}/>
          <ScoringGraph
            title={'Composite Health Index'}
            seriesData={decisionScores}
            anomalies={alertAnomalies}
            timeRange={timeRange}
            updateTimeRange={updateTimeRange}
          />
          <Box m={1}/>
          <ScoringGraph
            title={'Detection'}
            seriesData={detectionScores}
            anomalies={alertAnomalies}
            timeRange={timeRange}
            updateTimeRange={updateTimeRange}
          />
          <Box m={1}/>
          <TimeRangeSelector
            timeRange={timeRange}
            updateTimeRange={updateTimeRange}
          />
        </FoldableSection>

      </Grid>
    </Grid>
  );


  function saveAlert(newAlert: Alert) {
    patchAlert(newAlert.id, newAlert).then(response => {
      setAlert(response);
    })
  }

  function highchartsCallback(chart: any) {
    setChartSeries(chart.series);
  }
}

export default AlertDetail;

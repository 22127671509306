import {
  Drawer,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, Menu as MenuIcon, Settings } from '@material-ui/icons';
import React, { useState } from 'react';
import { useAuth0 } from '../../auth';
import { useHistory, useLocation } from "react-router-dom";
import {startCase} from 'lodash';

const useStyles = makeStyles(theme => ({
  menu: {
    transformOrigin: 'center bottom',
    borderRadius: "0 0 4px 4px",
    border: `1px solid ${theme.palette.background.default}`,
    borderTop: "0px"
  },
  accountBtn: {
    margin: `0 ${theme.spacing(1)}px`,
    order: 3
  },
  indicator: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up("sm")]: {
      flexFlow: 'row nowrap',
    },
    [theme.breakpoints.down("xs")]: {
      flexFlow: 'row wrap',
    },
  },
  logo: {
    maxHeight: 24,
    height: '100%',
    width: 'auto',
    [theme.breakpoints.down("md")]: {
      order: 1
    }
  },
  drawer: {
    height: "100%",
    overflowX: "auto",
    flex: '1 1 auto',
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column',
      '& .MuiTabs-flexContainer': {
        alignItems: 'center',
        flexDirection: "column"
      }
    },
    [theme.breakpoints.up("lg")]: {
      '& .MuiDrawer-paper': {
        height: '100%',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        borderBottom: "none",
        flex: '1 1 auto',
        flexDirection: 'row',
      }
    }
  },
  sideBarButton: {
    order: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    },
  },
  drawerButton: {
    [theme.breakpoints.up("lg")]: {
      display: "none"
    },
  },
  selectedTab: {
    color: theme.palette.primary.main
  }
}));
interface Props {
  pages: string[]
}
function Topbar(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    pages,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  let path = location.pathname;
  if (path === '/' || path.includes('/alerts')) {
    path = '/alerts';
  }
  let pageTabs = pages.reduce((tabs: any[], page: string) => {
    const basePath = page.split('/:')[0]
    return [...tabs, {
      name: basePath.replace('/', ''),
      path: basePath
    }]
  }, []);

  return (
    <Box boxShadow={3} className={classes.box}>
      <Button
        className={classes.drawerButton}
        onClick={() => setOpen(true)}
      >
        <MenuIcon /> &nbsp;
        <span className={classes.selectedTab}>
          {startCase(location.pathname.split('/')[1])}
        </span>
      </Button>
      <Drawer
        anchor="top"
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClick={() => setOpen(false)}
        className={classes.drawer}
      >
        <Tabs
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={path}
          orientation={isMobile ? "vertical" : "horizontal"}
          classes={{ indicator: classes.indicator }}
          onChange={(event: object, value: any) => history.push(value)}
        >
          {pageTabs.map((tab: any) => (
            <Tab key={tab.name} label={tab.name} value={tab.path} />
          ))}
        </Tabs>

        <Button onClick={() => setOpen(false)} className={classes.drawerButton}>
          <ExpandLess />
        </Button>
      </Drawer>
      <img alt="Decision IQ" className={classes.logo}
           src={process.env.PUBLIC_URL + '/logoLight.png'} />
      <IconButton
        className={classes.accountBtn}
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-label="user-menu-btn drawer"
        edge="end"
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
      >
        <Settings />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        transition
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.menu} elevation={3}>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuItem
                  onClick={() => logout({
                    returnTo: window.location.origin
                  })}
                >
                  Log Out
                </MenuItem>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default Topbar;

import React, {useEffect, useState} from 'react';
import {fromUnixTime, getUnixTime, parse} from 'date-fns';
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import {RawData, RawDataPoint} from "../../models/RawData";
import {uploadLiveVariableData} from "../../api";
import {VARIABLE_TYPE} from "../../models";
import {listToOptions} from "../../util/helpers";

const Papa = require("papaparse/papaparse.min.js");

const useStyles = makeStyles(theme => ({
  progress: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    '& .MuiLinearProgress-root': {
      display: 'flex',
      flex: '0 1 100%',
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap'
  },
  modalBody: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
}));

interface Props {
  file: File | null,
  closeUpload: Function,
}

function RawDataForm (props: Props) {
  const classes = useStyles();

  const {
    file,
    closeUpload,
  } = props;

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        step: function(result: any, parser: any) {
          setUploadFirstRow(result.data);
          parser.abort();
          return false;
        },
      });
    }
  }, [file]);

  enum TIMESTAMP_DENOMS {
    Seconds = 1,
    Millseconds = 1000,
    Nanoseconds = 1000000
  }
  const [timestampColumn, setTimestampColumn] = useState('');
  const [timestampType, setTimestampType] = useState('');
  const [timestampParseMethod, setTimestampParseMethod] = useState('');
  const [timestampParseTest, setTimestampParseTest] = useState<any | null>(null);

  const [dataType, setDataType] = useState<VARIABLE_TYPE | ''>('');
  const [waveformFields, setWaveformFields] = useState<Set<string> | null>(null);
  const [waveformDataField, setWaveformDataField] = useState<string>('');
  const [waveformFreqField, setWaveformFreqField] = useState<string>('');

  const LOADING = -1;
  const [parseProgress, setParseProgress] = useState(LOADING);
  const [uploadProgress, setUploadProgress] = useState(LOADING);
  const [uploadFirstRow, setUploadFirstRow] = useState<any | null>(null);

  return (
    <Modal
      open={!!file}
      className={classes.modal}
    >
      <Fade in={!!file}>
        <Paper className={classes.modalBody}>
          {renderHeader()}
          <Box m={1}/>

          {renderParseForm()}

          <Box m={1}/>
          {renderProgress()}
        </Paper>
      </Fade>
    </Modal>
  );

  function resetState() {
    setParseProgress(LOADING);
    setUploadProgress(LOADING);
    setUploadFirstRow(null);
    setTimestampColumn('');
    setTimestampType('');
    setTimestampParseMethod('');
    setTimestampParseTest(null);
    setDataType('');
    setWaveformFields(null);
    setWaveformDataField('');
    setWaveformFreqField('');
  }

  function renderHeader() {
    return (
      <Typography variant='h4' className={classes.modalHeader}>
        Parsing "{file ? file.name : null}"
        <IconButton onClick={(event) => {
          resetState();
          closeUpload();
        }}>
          <CloseIcon />
        </IconButton>
      </Typography>
    );
  }

  function renderParseForm() {
    if(!uploadFirstRow) {
      return null;
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel id='timestamp-col-input-label'>Timestamp Column</InputLabel>
              <Select
                labelId='timestamp-col-input-label'
                onChange={handleSelectTimestampColumn}
                value={timestampColumn}
                disabled={parseProgress !== LOADING}
              >
                {listToOptions(Object.keys(uploadFirstRow))}
              </Select>
              <FormHelperText>Select the column used for the timestamp.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {renderTimestampParseInput()}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='default'
              onClick={() => setTimestampParseTest(
                parseTimestampValue(uploadFirstRow[timestampColumn])
              )}
              disabled={!timestampParseMethod}
            >
              Test Timestamp Parsing
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle2'>Sample Timestamp:</Typography>
            {timestampParseTest ? timestampParseTest.toString() : null}
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id='data-type-input-label'>Data Type</InputLabel>
              <Select
                labelId='data-type-input-label'
                onChange={handleDataTypeSelect}
                value={dataType}
                disabled={parseProgress !== LOADING}
              >
                {Object.keys(VARIABLE_TYPE).map((key: string) => {
                  const type = VARIABLE_TYPE[key as keyof typeof VARIABLE_TYPE];
                  return <MenuItem key={type} value={type}> {type}</MenuItem>;
                })}
              </Select>
              <FormHelperText>Select if your data is time-series or waveform.</FormHelperText>
            </FormControl>
          </Grid>
          { dataType === VARIABLE_TYPE.WAVE.valueOf() && waveformFields
            ? <Grid item xs={6}>
                <FormControl>
                  <InputLabel id='wave-field-input-label'>Waveform Field</InputLabel>
                  <Select
                    labelId='wave-field-input-label'
                    onChange={(event) => setWaveformDataField(event.target.value as string)}
                    value={waveformDataField}
                    disabled={parseProgress !== LOADING}
                  >
                    {listToOptions([...waveformFields])}
                  </Select>
                  <FormHelperText>Select the json field containing the array of data points.</FormHelperText>
                </FormControl>
              </Grid>
            : null
          }
          { dataType === VARIABLE_TYPE.WAVE.valueOf() && waveformFields
            ?<Grid item xs={6}>
                <FormControl>
                  <InputLabel id='freq-field-input-label'>Frequency Field</InputLabel>
                  <Select
                    labelId='freq-field-input-label'
                    onChange={(event) => setWaveformFreqField(event.target.value as string)}
                    value={waveformFreqField}
                    disabled={parseProgress !== LOADING}
                  >
                    {listToOptions([...waveformFields])}
                  </Select>
                  <FormHelperText>Select the json field containing the frequency value.</FormHelperText>
                </FormControl>
              </Grid>
            : null
          }
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              onClick={parseFile}
              disabled={parseProgress !== LOADING || !(
                timestampColumn && timestampType && timestampParseMethod
                && (dataType === VARIABLE_TYPE.TIME || (dataType === VARIABLE_TYPE.WAVE
                    && waveformFields && waveformDataField && waveformFreqField
                ))
              )}
            >
              Parse &amp; Upload
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  function renderProgress() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          Parsing:
          <span className={classes.progress}>
            <LinearProgress
              value={parseProgress}
              variant={parseProgress === LOADING ? 'indeterminate' : 'determinate'}
            />
            {parseProgress !== LOADING
              ? <Typography>&nbsp;{Math.floor(parseProgress)}%</Typography>
              : null
            }
          </span>
        </Grid>
        <Grid item xs={12}>
          Uploading:
          <span className={classes.progress}>
            <LinearProgress
              value={uploadProgress}
              variant={uploadProgress === LOADING ? 'indeterminate'  : 'determinate'}
            />
            {uploadProgress !== LOADING
              ? <Typography>&nbsp;{Math.floor(uploadProgress)}%</Typography>
              : null
            }
          </span>
        </Grid>
    </Grid>)
  }

  function handleSelectTimestampColumn(event: any) {
    const column = event.target.value as string;
    const firstTimestamp = uploadFirstRow[column];
    setTimestampColumn(column);
    if (isNaN(firstTimestamp)) {
      setTimestampType(typeof firstTimestamp)
    } else {
      setTimestampType(typeof parseFloat(firstTimestamp))
    }
  }

  function renderTimestampParseInput() {
    switch(timestampType) {
      case 'string':
        return (
          <FormControl>
            <Tooltip title={<>
              Case-sensitive Date/Time format for the string timestamps<br/>
              y: numeric year,<br/>
              M: numeric month,<br/>
              d: numeric day,<br/>
              E: text day of week,<br/>
              h: 1-12 hour,<br/>
              H: 0-23 hour,<br/>
              m: minute,<br/>
              s: second,<br/>
              x: +00 (use uppercase X to handle time zone e.g. PDT or ET),<br/>
              xxxx: +0000 or +000000,<br/>
              xxxxx: +00:00 or +00:00:00,<br/>
              /, -, :, [space], ... - separators
            </>}>
            <TextField
              label='Format' fullWidth
              onChange={(event) => setTimestampParseMethod(event.target.value as string)}
              value={timestampParseMethod}
              disabled={parseProgress !== LOADING}
            />
            </Tooltip>
          </FormControl>
        );

      case 'number':
        return (
          <FormControl>
            <InputLabel id='timestamp-parse-input-label'>Timestamp Format</InputLabel>
            <Select
              labelId='timestamp-parse-input-label'
              onChange={(event) => setTimestampParseMethod(event.target.value as string)}
              value={timestampParseMethod}
              disabled={parseProgress !== LOADING}
            >
              {Object.keys(TIMESTAMP_DENOMS).filter((k: any) => isNaN(k)).map((key) => {
                return <MenuItem key={key} value={key}>{key}</MenuItem>
              })}
            </Select>
            <FormHelperText>
              Precision used for the integer/floating point timestamps.
            </FormHelperText>
          </FormControl>
        )
    }
  }

  function parseTimestampValue(timestampValue: any): Date {
    if (timestampType === 'string') {
      return parse(timestampValue, timestampParseMethod, new Date())
    } else if (timestampType === 'number') {
      const denom = TIMESTAMP_DENOMS[timestampParseMethod as keyof typeof TIMESTAMP_DENOMS];
      return fromUnixTime(timestampValue / denom)
    } else {
      throw Error(`Unsupported timestamp type '${timestampType}'.`);
    }
  }

  function handleDataTypeSelect(event: any) {
    const dataType: VARIABLE_TYPE = event.target.value;
    setDataType(dataType);
    if (dataType === VARIABLE_TYPE.WAVE.valueOf()) {
      setWaveformFields(
        Object.keys(uploadFirstRow).filter(
          col => col !== timestampColumn && uploadFirstRow[col] !== ''
        ).reduce((set, col) => {
          const data = JSON.parse(uploadFirstRow[col]);
          Object.keys(data).forEach(field => set.add(field));
          return set;
        }, new Set<string>())
      )
    }
  }

  function parseFile() {
    if(!!file && !!uploadFirstRow && !!timestampColumn) {
      Papa.LocalChunkSize = 500000;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        chunk: (results: any, parser: any) => {
          parser.pause();
          const progress = results.meta.cursor / file.size * 100;
          setParseProgress(progress);
          let data: RawData = results.data.reduce((chunkData: any, entry: any, index: number): RawData => {
            const timestamp: number = getUnixTime(parseTimestampValue(entry[timestampColumn]));
            delete entry[timestampColumn];
            return Object.keys(entry).reduce((entryData: any, col: string): RawData => {
              const dataPoint: RawDataPoint = {
                timestamp,
                value: null
              };
              if (dataType === VARIABLE_TYPE.TIME) {
                dataPoint.value = parseFloat(entry[col])
              } else {
                const data = JSON.parse(entry[col]);
                dataPoint.value = data[waveformDataField];
                dataPoint.sample_frequency = data[waveformFreqField];
                delete data[waveformDataField];
                delete data[waveformFreqField];
                dataPoint.meta_data = data;
              }
              if (dataPoint.value !== null && !isNaN(dataPoint.value)) {
                if (!(col in entryData)) {
                  entryData[col] = [];
                }
                entryData[col].push(dataPoint);
              }
              return entryData;
            }, {...chunkData})
          }, {});
          uploadLiveVariableData(data).then(res => {
            setUploadProgress(progress);
            if (progress >= 100) {
              closeUpload()
            }
            parser.resume()
          });
        }
      })
    }
  }
}

export default RawDataForm;

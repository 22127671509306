import {ModelGroup} from "./ModelGroup";
import {ProductionRun} from "./ProductionRun";

export enum ALERT_STATUS {
  NEEDS_ATTENTION = 'Inspect',
  TRACKING = 'Tracking',
  RESOLVING = 'Resolving',
  CLOSED = 'Closed',
}

export enum STATUS_COLORS {
  NEEDS_ATTENTION = 'error',
  TRACKING = 'warning',
  RESOLVING = 'info',
  CLOSED = 'success',
}

export enum ALERT_CLASS {
  CLASS_DEGREDATION = "degradation",
  CLASS_BAD_DATA = "bad/missing input data",
  CLASS_NEW_MODE = "new baseline/operating mode",
  CLASS_STARTUP = "startup condition",
  CLASS_TRANSIENT = "transient event",
  CLASS_NORMAL = "within normal range",
  CLASS_DOWNTIME = "downtime period",
  CLASS_IRRELEVANT = "irrelevant change",
  CLASS_MORE_INFO = "need more information",
  CLASS_OTHER = "other",
}

export enum ALERT_EVAL {
  EVAL_DATA = 'Data Anomaly',
  EVAL_FAULT = 'Fault Detected',
  EVAL_OPERATION = 'Operation Anomaly',
  EVAL_RETRACTED = 'Retracted',
  EVAL_FEEDBACK = 'Need Feedback',
  EVAL_FALSE_ALERT = 'False Alert',
  EVAL_RETRAIN = 'Retrain',
}

export interface AlertStatusHistory {
  status: keyof typeof ALERT_STATUS,
  timestamp: number,
  flag_event: number,
}

export interface Alert {
  id: number,
  status: keyof typeof ALERT_STATUS,
  classification: keyof typeof ALERT_CLASS | null,
  evaluation: keyof typeof ALERT_EVAL | null,
  model_input: number,
  approved: boolean,
  version: string,
  start_timestamp: number,
  end_timestamp: number,
  last_timestamp: number,
  hours_since_last_flag: number,
  model_group: ModelGroup,
  production: ProductionRun[],
  time_open: number,
}

export type AlertAggregates = {
  [key in keyof typeof ALERT_STATUS]: number
}

import {createSlice} from '@reduxjs/toolkit';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MaintenanceState {
}

const initialState: MaintenanceState = {
};

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
  }
});


// eslint-disable-next-line no-empty-pattern
export const {  } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
import React, {Component, createContext, useContext} from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client, {Auth0ClientOptions} from '@auth0/auth0-spa-js';
import {setAccessToken} from "./api";

interface ContextValueType {
  isAuthenticated?: boolean,
  user?: any,
  isLoading?: boolean,
  handleRedirectCallback?: () => void,
  getIdTokenClaims?: (...p: any) => any,
  loginWithRedirect?: (...p: any) => any,
  getTokenSilently?: (...p: any) => any,
  logout?: (...p: any) => any,
  storeRedirectPath?: (path: string) => void,
  retrieveRedirectPath?: () => string
}


// create the context
export const Auth0Context: any = createContext<ContextValueType | null>(null);
export const useAuth0: any = () => useContext(Auth0Context);

interface IState {
  auth0Client: any,
  isLoading: boolean,
  isAuthenticated: boolean,
  user?: any;
}

function clientId() {
  if (process.env.REACT_APP_API === 'production') {
    return '2e150AQ4IZyz2ij1pkYgJSip3QQURupc';
  } else {
    return 'eAugVEGOW60NKEP3guUCfxXWV8omFogy';
  }
}

export class Auth0Provider extends Component<{}, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isAuthenticated: false,
      user: null,
      auth0Client: Auth0Client,
    };
  }

  config: Auth0ClientOptions = {
    domain: 'decisioniq.auth0.com',
    client_id: clientId(),
    audience: 'https://decisioniq.com',
    response_type: 'token access_token',
    useRefreshTokens: true,
    cacheLocation: 'localstorage'
  };

  componentDidMount() {
    this.initializeAuth0();
  }

  setAuth0Data = async () => {
    const isAuthenticated = await this.state.auth0Client.isAuthenticated();
    const user = isAuthenticated ? await this.state.auth0Client.getUser() : null;
    if (isAuthenticated) {
      setAccessToken( await this.state.auth0Client.getTokenSilently() );
    }
    this.setState({ isLoading: false, isAuthenticated, user });
  };

  // initialize the auth0 library
  initializeAuth0 = async () => {
    const auth0Client = await createAuth0Client(this.config);

    this.setState({ auth0Client });

    // check to see if they have been redirected after login
    if (window.location.search.includes('code=')) {
      return this.handleRedirectCallback();
    }

    await this.setAuth0Data();
  };

  handleRedirectCallback = async () => {
    this.setState({ isLoading: true });

    await this.state.auth0Client.handleRedirectCallback();

    await this.setAuth0Data();

    const redirectPath = this.retrieveRedirectPath();
    window.location.href = `${window.location.origin}${redirectPath}`;
    window.history.replaceState({}, document.title, redirectPath);
  };

  storeRedirectPath = (path: string) => {
    localStorage.setItem('redirect', path);
  };

  retrieveRedirectPath = (): string => {
    const path = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    return path || '';
  };

  render() {
    const { auth0Client, isLoading, isAuthenticated, user } = this.state;
    const { children } = this.props;

    const configObject = {
      auth0Client,
      isLoading,
      isAuthenticated,
      user,
      loginWithRedirect: (...p: any) => {
        this.storeRedirectPath(window.location.pathname);
        auth0Client.loginWithRedirect(...p)
      },
      getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
      getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
      logout: (...p: any) => auth0Client.logout(...p),
    };

    return <Auth0Context.Provider
      value={configObject}>
      {children}
    </Auth0Context.Provider>;
  }
}

import React, {useEffect, useState} from 'react';
import {
  ALERT_STATUS,
  Asset,
  NODE_TYPES,
  STATUS_COLORS,
} from "../../models";
import {Skeleton} from "@material-ui/lab";
import {
  queryAlertAggregates,
  queryAsset,
  queryDataAggregates,
  queryModelGroupAggregates
} from "../../api";
import {StatusChip, TableIcons} from "../index";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {AccountTree, Add, Business, Delete, Edit, Memory, Warning} from "@material-ui/icons";
import {Box, Card, List, ListItem, Paper, TableContainer, Tooltip} from "@material-ui/core";
import AssetForm from "../forms/assetForm";
import MaterialTable from "material-table";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  card: {
    display: 'flex',
    height: "10rem",
  },
  list: {
    padding: 0
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    fontSize: theme.fontStyling.table.fontSize,
    padding: theme.spacing(.5),
    minWidth: '10rem'
  },
  asset: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  assetFormIcon: {
    color: theme.palette.text.disabled,
    marginLeft: '.5rem',
    fontSize: '1rem',
    '&:hover': {
      color: theme.palette.text.primary,
    }
  },
  table: {
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    '& th': {
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    '& td': {
      padding: theme.spacing(1),
      lineHeight: 'auto',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    }
  },
  warning: {
    fontWeight: 550,
    color: theme.palette.error.main
  }
}));

interface Props {
  setAssetFilters: Function
}

enum FormMethod {
  CREATE, UPDATE, DELETE
}

function AssetFinder(props: Props) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    setAssetFilters
  } = props;

  const [assets, setAssets] = useState<Asset[]>([]);
  const [columns, setColumns] = useState<Array<Set<Asset>>>([]);
  const [selectedNodes, setSelectedNodes] = useState<Set<Asset>>(new Set());
  const [aggregations, setAggregations] = useState<{[key: string]: object}>({});
  const [loadingAggs, setLoadingAggs] = useState(false);

  const [openAssetForm, setOpenAssetForm] = useState(false);
  const [assetFormData, setAssetFormData] = useState<any>();


  useEffect(() => {
    queryAsset({
      node_type: NODE_TYPES.company.valueOf()
    }).then(assetList => {
      setAssets(prevAssets => [...prevAssets, ...assetList]);
      selectNode(assetList[0], -1)
    })
    //eslint-disable-next-line
  }, []);

  if (!assets) {
    return (
      <Skeleton variant='rect' animation='wave' style={{
        height: '10rem',
        width: '100%'
      }}/>
    )
  }

  return (
    <Box className={classes.root}>
      {columns.map((column: Set<Asset>, depth: number) =>
        <Card className={classes.card} key={depth}>
          {depth < columns.length - 1
            ? renderColumn(column, depth)
            : renderTable(column, depth)
          }
        </Card>
      )}
      <AssetForm
        open={openAssetForm}
        {...assetFormData}
        closeForm={closeForm}
      />
    </Box>
  );

  function renderColumn(column: Set<Asset>, depth: number) {
    return (
      <List className={classes.list}>
        {[...column].map((asset: Asset) => (
          <ListItem
            dense button
            key={asset.uid}
            selected={selectedNodes.has(asset)}
            className={classes.listItem}
            onClick={() => handleAssetClick(asset, depth)}
          >
            {renderAsset(asset, depth)}
          </ListItem>
        ))}
      </List>
    )
  }

  function handleAssetClick(asset: Asset, depth: number) {
    if (selectedNodes.has(asset)) {
      deselectNode(asset, depth);
    } else {
      selectNode(asset, depth);
    }
  }

  async function selectNode(asset: Asset, depth: number) {
    setAssetFilters({
      type: 'select', asset
    });

    setSelectedNodes(prevState =>
      new Set([...prevState, asset])
    );

    const children = await getChildren(asset, depth);
    if (children.length) {
      setColumns(prevState => {
        const newState = [...prevState];
        newState[depth + 1] = new Set([
          ...newState[depth + 1]
            ? newState[depth + 1]
            : [],
          ...children
        ]);
        return newState;
      });
    }
  }

  async function deselectNode(asset: Asset, depth: number) {
    // remove children if there's a next level
    const children = await getChildren(asset, depth);
    if (children.length) {
      for (const child of children) {
        if (selectedNodes.has(child)) {
          await deselectNode(child, depth + 1);
        }
      }
    }
    setColumns(prevColumns => {
      const newColumns = [...prevColumns];
      if (newColumns[depth + 1]) {
        children.forEach(child => newColumns[depth + 1].delete(child));
        if (!newColumns[depth + 1].size) {
          newColumns.pop();
        }
      }
      return newColumns;
    });

    // Use prevState to eliminate race condition with removing children from selected nodes
    setSelectedNodes(prevState => {
      prevState.delete(asset);
      return new Set([...prevState]);
    });

    setAssetFilters({
      type: 'deselect',
      asset,
      parent: assets?.find(a =>
        a.path === asset.parent_path
      )
    });
  }

  function renderTable(column: Set<Asset>, depth: number) {
    return (
      <TableContainer component={Paper} className={classes.table} key={`${depth}-${column.size}`}>
        <MaterialTable
          icons={TableIcons}
          components={{ Toolbar: props => null }}
          onRowClick={(event: any, rowData: any) =>
            handleAssetClick(rowData.asset, depth)
          }
          columns={[
            {
              field: 'asset',
              width: '10%',
              render: (rowData: any) => renderAsset(rowData.asset, depth)
            },
            ...Object.entries(ALERT_STATUS).map(([key, value]) => ({
              title: <StatusChip
                      status={ALERT_STATUS[key as keyof typeof ALERT_STATUS]}
                      color={STATUS_COLORS[key as keyof typeof ALERT_STATUS]}
                      width={10}
                      size={'small'}
                    />,
              field: `${value}-Alert`,
              width: `10%`,
              render: (rowData: any) => rowData[value]
            })),
            {
              title: 'Data Streaming',
              field: 'dataStreaming',
              width: `10%`,
              render: (rowData: any) =>
                <span className={rowData.dataWarning ? classes.warning : ""}>
                  {rowData.dataStreaming}
                </span>
            },
            {
              title: 'Models Running',
              field: 'modelsRunning',
              width: `10%`,
              render: (rowData: any) =>
                <span className={rowData.modelWarning ? classes.warning : ""}>
                  {rowData.modelsRunning}
                </span>
            },
          ]}
          isLoading={loadingAggs}
          data={loadingAggs ? [] : [...column].map(asset => aggregations[asset.uid])}
          options={{
            rowStyle: rowData => {
              const style =  {...theme.fontStyling.table};
              if (selectedNodes.has(rowData.asset)) {
                style.backgroundColor = theme.palette.action.selected
              }
              return style;
            },
            paging: false,
            sorting: false
          }}
        />
      </TableContainer>
    )
  }

  async function getChildren(asset: Asset, depth: number): Promise<Asset[]> {
    let children = assets?.filter(a => a.parent_path === asset.path);
    if (!children?.length) {
      children = await queryAsset({path__children_of: asset.path});
      setAssets(prevAssets => [...prevAssets, ...children]);
      queryAggregates(children)
    }
    return children;
  }

  function queryAggregates(assetList: Asset[]) {
    setLoadingAggs(true);
    const aggQueries = assetList.map(asset => {
      if (aggregations && aggregations[asset.uid]) {
        return new Promise(
          (resolve) => resolve(aggregations[asset.uid])
        );
      }
      const params = {
        aggregates: true,
        asset__path__descendant_of_included: asset.path,
      };
      return Promise.all([
        queryDataAggregates(params),
        queryModelGroupAggregates(params),
        queryAlertAggregates(params),
      ]).then(results =>
        parseAggregateResults(results[0], results[1], results[2])
      );
    });
    Promise.all(aggQueries).then((results: any) => {
      const assetAggs = assetList.reduce(
        (state: any, asset: Asset, index: number) => {
          state[asset.uid] = {asset, ...results[index]};
          return state;
        }, {}
      );
      setAggregations(prevAggs => ({...prevAggs, ...assetAggs}));
      setLoadingAggs(false);
    });
  }

  function parseAggregateResults(variableAgg: any, modelGroupAgg: any, alertAgg: any) {
    const totalVariables = Object.values(variableAgg).reduce((a: any, b: any) => a + b);
    const totalModelGroups = Object.values(modelGroupAgg).reduce((a: any, b: any) => a + b);

    const dataWarning = totalVariables !== variableAgg['STREAMING'];
    const modelWarning = totalModelGroups !== modelGroupAgg['RUNNING'];

    const streamingRatio = variableAgg
      ? variableAgg['STREAMING'] + '/' + totalVariables
      : 'No Data';

    const modelRatio = modelGroupAgg
      ? modelGroupAgg['RUNNING'] + '/' + totalModelGroups
      : 'No Data';

    const dataObj: any = {
      dataWarning,
      modelWarning,
      dataStreaming: streamingRatio,
      modelsRunning: modelRatio
    };
    Object.entries(ALERT_STATUS).forEach(([key, value]) => {
      dataObj[value] = alertAgg[key as keyof typeof ALERT_STATUS] || 0;
    });
    return dataObj;
  }

  function renderAsset(asset: Asset, depth: number) {
    let assetIcon = null;

    let color = theme.palette.text.primary;
    const agg = aggregations?.[asset.uid] as any;
    if(agg) {
      if (agg[ALERT_STATUS.NEEDS_ATTENTION.valueOf()] > 0) {
        color = theme.palette[STATUS_COLORS.NEEDS_ATTENTION].main;
      } else if (agg[ALERT_STATUS.TRACKING.valueOf()] > 0) {
        color = theme.palette[STATUS_COLORS.TRACKING].main;
      } else if (agg[ALERT_STATUS.RESOLVING.valueOf()] > 0) {
        color = theme.palette[STATUS_COLORS.RESOLVING].main;
      } else {
        color = theme.palette[STATUS_COLORS.CLOSED].main;
      }
    }

    switch (asset.node_type) {
      case NODE_TYPES.division:
        assetIcon = <AccountTree style={{color}}/>;
        break;
      case NODE_TYPES.site:
        assetIcon = <Business style={{color}}/>;
        break;
      case NODE_TYPES.location:
      case NODE_TYPES.equipment:
        assetIcon = <Memory style={{color}}/>;
        break;
      default:
        break;
    }

    if (agg?.dataWarning || agg?.modelWarning) {
      assetIcon = <Warning style={{
        color: theme.palette[STATUS_COLORS.TRACKING].main
      }}/>;
    }

    return (
      <Box className={classes.asset}>
        {assetIcon} &nbsp;
        <span style={{marginRight: 'auto'}}>
          {asset.name_list.slice(-1)}
        </span>
        <Tooltip title={'Add Child Asset'}>
          <Add
            className={classes.assetFormIcon}
            onClick={(event) => openForm(
              event, asset, depth, FormMethod.CREATE
            )}
          />
        </Tooltip>
        <Tooltip title={'Edit this Asset'}>
          <Edit
            className={classes.assetFormIcon}
            onClick={(event) => openForm(
              event, asset, depth, FormMethod.UPDATE
            )}
          />
        </Tooltip>
        <Tooltip title={'Delete this Asset'}>
          <Delete
            className={classes.assetFormIcon}
            onClick={(event) => openForm(
              event, asset, depth, FormMethod.DELETE
            )}
          />
        </Tooltip>
      </Box>
    );
  }


  function openForm(clickEvent: any, asset: Asset, depth: number, method: FormMethod) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
    switch (method) {
      case FormMethod.CREATE:
        setAssetFormData({
          parents: [asset],
          depth
        });
        break;
      case FormMethod.UPDATE:
        setAssetFormData({
          parents: assets.filter(a =>
            a !== asset && !a.path.includes(asset.path)
          ),
          asset,
          depth
        });
        break;
      case FormMethod.DELETE:
        setAssetFormData({
          parents: assets.filter(a =>
            a.path === asset.parent_path
          ),
          deleting: true,
          asset,
          depth,
        });
        break;
    }
    setOpenAssetForm(true);
  }

  async function closeForm(newAsset?: Asset, parent?: Asset) {
    if (newAsset && parent) {
      const prevAsset = assetFormData.asset;
      const prevDepth = assetFormData.depth;
      // handle edited asset
      if (prevAsset) {
        // deselect the node if necessary
        if (selectedNodes.has(prevAsset)) {
          await deselectNode(prevAsset, prevDepth);
        }
        // calculate the depth of the new instance
        const newDepth = prevDepth + (newAsset.name_list.length - prevAsset.name_list.length);
        // remove old asset from asset list, columns, and selected nodes
        setAssets(prevAssets => {
          prevAssets = prevAssets.filter(a => a.uid !== prevAsset.uid);
          // if the path changed
          if (prevAsset.path !== newAsset.path) {
            prevAssets = prevAssets.filter(a => !a.parent_path.includes(prevAsset.path))
          }
          return [...prevAssets]
        });
        setColumns(prevColumns => {
          prevColumns[prevDepth].delete(prevAsset);
          return [...prevColumns];
        });
        // Add new asset to asset list and new column if necessary
        if (!assetFormData.deleting && selectedNodes.has(parent)) {
          setAssets(prevAssets => {
            prevAssets.push(newAsset);
            return [...prevAssets]
          });
          setColumns(prevColumns => {
            prevColumns[newDepth].add(newAsset);
            return [...prevColumns];
          });
        }
      // handle new asset with selected parent
      } else if (selectedNodes.has(parent)) {
        setAssets(prevAssets => [...prevAssets, newAsset]);
        setColumns(prevColumns => {
          if (columns[prevDepth + 1]) {
            prevColumns[prevDepth + 1].add(newAsset);
          } else {
            prevColumns.push(new Set([newAsset]))
          }
          return [...prevColumns]
        });
      }
    }
    setOpenAssetForm(false)
  }

}

export default AssetFinder;

import {createSlice} from '@reduxjs/toolkit';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductionState {
}

const initialState: ProductionState = {
};

const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
  }
});


// eslint-disable-next-line no-empty-pattern
export const {  } = productionSlice.actions;

export default productionSlice.reducer;
import Highcharts from 'highcharts/highstock';
import Annotations from 'highcharts/modules/annotations';
import HighchartsReact from 'highcharts-react-official';
import React, {useEffect, useState} from 'react';

import {Anomaly, STATUS_COLORS, Threshold, TimeRange} from "../../models";
import {useTheme} from "@material-ui/core";

Annotations(Highcharts);

interface Props {
  title?: string,
  titleJustify?: 'left' | 'center' | 'right',
  data?: Array<Highcharts.SeriesOptionsType>,
  anomalies?: Array<Anomaly>,
  thresholds?: Threshold,
  markedPoints?: Array<string>,
  maxValue?: number,
  minValue?: number,
  showLegend?: boolean,
  showTimeline?: boolean,
  size?: 'small' | 'medium' | 'large',
  yLabelTitle?: string,
  timeRange: TimeRange,
  updateTimeRange: Function,
  mouseOutEventHandler?: Function,
  highchartsCallback?: any
}

function LineGraph(props: Props) {
  const theme = useTheme();
  const {
    title = '',
    titleJustify = 'left',
    data = [],
    anomalies = [],
    thresholds = null,
    markedPoints = [],
    maxValue = null,
    minValue = null,
    showLegend = false,
    showTimeline = false,
    size = 'large',
    yLabelTitle = null,
    timeRange,
    updateTimeRange,
    highchartsCallback
  } = props;

  const THRESHOLD_THICKNESS = 2;
  let annotationPoints: Array<object> = [];
  if (markedPoints) {
    annotationPoints = markedPoints.map(id => {
      return { point: id };
    });
  }

  let chartHeight = '';
  switch (size) {
    // Would of preferred to use a more dynamic sizing here, but % only look good at specific aspect
    //  ratios, and HighCharts 8.0 does not support using REMs for chartHeight
    case 'small':
      chartHeight = '150px';
      break;
    case 'medium':
      chartHeight = '300px';
      break;
    case 'large':
      chartHeight = '600px';
      break;
    default:
      // aka 'full'
      chartHeight = '100%';
      break;
  }
  
  const initialOptions: Highcharts.Options = {
    title: {
      text: title,
      align: titleJustify || 'left',
      style: {
        color: theme.graphs.appText,
        fontSize: theme.graphs.fontSize,
        fontFamily: theme.typography.fontFamily
      },
      margin: 30
    },
    series: data,
    tooltip: {
      split: false,
      shared: true,
      pointFormatter: function(this: any) {
        return `<span style="color:${this.color}">●</span> ${this.series.name}: ${this.y.toFixed(2)}<br/>`;
      }
    },
    chart: {
      type: 'line',
      backgroundColor: theme.graphs.chartBackground,
      plotBackgroundColor: theme.graphs.chartPlotBackground,
      borderRadius: theme.graphs.borderRadius,
      marginRight: theme.graphs.marginRight,
      marginLeft: theme.graphs.marginLeft,
      height: chartHeight,
      zoomType: 'x',
    },
    colors: theme.graphs.series,
    credits: { enabled: false },
    legend: {
      enabled: showLegend,
      floating: true,
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      width: '100%',
      itemStyle: {
        color: theme.graphs.dimText,
      },
      itemHoverStyle: {
        color: theme.graphs.appText,
      },
      itemMarginTop: 20,
    },
    plotOptions: {
      series: {
        turboThreshold: 0, // This enables showing graphs with more than 1000 datapoints
        stickyTracking: false,
        events:{
          mouseOut: function (this: any) {
            this.chart.tooltip.hide();
          }
        }
      },
    },
    xAxis: {
      type: 'datetime',
      // minTickInterval: 60 * 1000,
      startOnTick: false,
      endOnTick: false,
      tickColor: theme.graphs.dimmerText,
      tickWidth: 0.5,
      lineColor: theme.graphs.chartPlotBackground,
      gridLineColor: theme.graphs.chartBackground,
      gridLineWidth: 1,
      gridZIndex: 0,
      labels: {
        style: {
          color: theme.graphs.dimmerText,
          fontSize: '0.7rem',
        },
      },
      dateTimeLabelFormats: {
        day: '%b %e',
      },
      ordinal: false,
      min: null,
      max: null,
      events: { setExtremes: setMinMax },
    },
    yAxis: {
      title: yLabelTitle
        ? {
            text: yLabelTitle,
            reserveSpace: false,
            align: 'middle',
            textAlign: 'center',
            style: { fontSize: '0.75rem' },
          }
        : {},
      gridLineColor: theme.graphs.chartBackground,
      max: maxValue,
      min: minValue,
      startOnTick: true,
      endOnTick: true,
      labels: {
        align: 'right',
        style: {
          color: theme.graphs.dimmerText,
          fontSize: '0.6rem',
        },
      },
      opposite: false,
    },
    navigator: {
      enabled: showTimeline,
      yAxis: {
        reversed: true,
        max: 0.2,
      },
    },
    rangeSelector: {
      enabled: showTimeline,
      inputEnabled: false,
      buttonTheme: {
        fill: theme.graphs.buttonBase,
        style: {
          fill: `linear-gradient(175deg, ${theme.graphs.buttonBase} 70%, ${theme.graphs.buttonShadow} 95%)`,
          color: theme.graphs.appText,
          cursor: 'pointer',
        },
        states: {
          hover: {
            fill: theme.graphs.buttonHighlight,
          },
          select: {
            fill: theme.graphs.buttonBase,
            style: {
              fontWeight: 'normal',
              color: theme.graphs.appText,
            },
          },
        },
      },
      verticalAlign: 'bottom',
    },
    annotations: [
      {
        draggable: '', // this disables annotations from being altered
        shapeOptions: {
          type: 'circle',
          fill: theme.palette.warning.main,
          stroke: theme.palette.error.main,
          strokeWidth: 1,
          r: 3, // radius
        },
        shapes: annotationPoints,
      },
    ],
    scrollbar: { enabled: false },
  };

  Object.assign(initialOptions.xAxis, drawAnomalyBands());
  Object.assign(initialOptions.yAxis, drawThresholdLines());
  if (!!timeRange && !!initialOptions) {
    Object.assign(initialOptions.xAxis, {
      min: timeRange.start,
      max: timeRange.end,
    });
  }

  // only need to pass in a partial update here:
  // https://www.npmjs.com/package/highcharts-react-official#optimal-way-to-update
  const [chartOptions, setChartOptions] = useState(initialOptions);
  useEffect(() => {
    setChartOptions({
      xAxis: {
        min: timeRange.start,
        max: timeRange.end,
      },
    });
  }, [timeRange.start, timeRange.end]);

  return (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="stockChart"
        options={chartOptions}
        callback={highchartsCallback}
      />
  );

  function drawAnomalyBands() {
    // In the SNC demo these were used to show different alerts over a CHI timeline, with the status
    //  history bar we're going to have now, this may no longer be needed 🤔
    if (anomalies) {
      return {
        plotBands: anomalies.map(anomaly => {
          return {
            color: theme.palette[STATUS_COLORS[anomaly.status]].main,
            from: anomaly.from,
            to: anomaly.to,
            zIndex: 0,
          };
        }),
      };
    }
    return {};
  }

  function drawThresholdLines() {
    const plotLines: Array<object> = [];

    if (thresholds) {
      plotLines.push({
        color: theme.palette.error.main,
        value: thresholds.default,
        width: THRESHOLD_THICKNESS,
        zIndex: 1,
      });

      if (thresholds.secondary) {
        plotLines.push({
          color: theme.palette.warning.main,
          value: thresholds.secondary,
          width: THRESHOLD_THICKNESS,
          zIndex: 1,
        });
      }

      if (thresholds.rul) {
        plotLines.push({
          color: theme.palette.info.main,
          value: thresholds.rul,
          width: THRESHOLD_THICKNESS,
          zIndex: 1,
        });
      }
    }

    return { plotLines };
  }

  function setMinMax(hcEvent: Highcharts.ExtremesObject) {
    if (updateTimeRange && hcEvent) {
      updateTimeRange({ start: hcEvent.min, end: hcEvent.max });
    }
  }
}

export default LineGraph;

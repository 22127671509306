import React, {useEffect} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Skeleton from '@material-ui/lab/Skeleton';

import {Waterfall, WaterfallAnnotations, WaterfallData} from '../../models';
import {isLoading} from '../../util/helpers';
import {useTheme} from "@material-ui/core";

const CONTAINER_ID = 'spectral-graphs';

interface Props {
  waterfallData?: WaterfallData
}

function SpectralGraphs(props: Props) {
  const theme = useTheme();
  const {
    waterfallData
  } = props;

  useEffect(() => {
    // This should fire once, after render with graphs present
    const container = document.getElementById('spectral-graphs');
    if (!!container && container.children.length > 1) {
      syncHighlights();
    }
    // eslint-disable-next-line
  }, [document.getElementById('spectral-graphs')]);

  // Loading state
  if (isLoading(waterfallData)) {
    return <Skeleton variant='rect' animation='wave' style={{
      width: '100%'
    }}/>
  }

  if (!waterfallData || !waterfallData.meta_data) {
    return <div> No corresponding data </div>;
  }

  const annotations = waterfallData.annotations;
  const max_freq = waterfallData.meta_data.max_freq;
  const waterfalls = waterfallData.meta_data.waterfalls;

  return (
    <div id={CONTAINER_ID}>
      {prepareGraphs().map(chartOptions => (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          key={chartOptions.title ? chartOptions.title.text : ''}
        />
      ))}
      {drawAnnotationLegend()}
    </div>
  );

  function prepareGraphs(): Array<Highcharts.Options> {

    if (!waterfalls) {
      return [];
    }

    const first_date = Object.keys(waterfalls)[0];
    const y_max = Object.values(waterfalls).reduce((largestWaterfall, currentWaterfall) => {
        const SCALING_MULTIPLIER = 1.2;

        const largestCurrent = currentWaterfall.reduce((largestData, currentData) => {
          let dataMax = Math.max(...currentData.data);
          dataMax = Math.round(dataMax * SCALING_MULTIPLIER * 100) / 100;

          largestData = Math.max(dataMax, largestData);

          return largestData;
        }, 0);

        largestWaterfall = Math.max(largestCurrent, largestWaterfall);

        return largestWaterfall;
      }, 0);

    return Object.entries(waterfalls)
      .map(([date_str, spectral_data]) => {
        return generateOptions(date_str, spectral_data);
      });

    function generateOptions(
      date_str: string,
      spectral_datas: Array<Waterfall>,
    ): Highcharts.Options {

      // get RPM if available and generate title for the sub-graph
      const { rpm } = spectral_datas[0];
      let title = date_str;
      if (rpm) {
        const roundedRpm = Math.round(rpm);
        title += ` (${roundedRpm} RPM)`
      }

      // create a legend for the first plot if there are more than one object for each day
      let legend_status = false;
      if (date_str === first_date && spectral_datas.length > 1) {
        legend_status = true;
      }

      // create the array of series for the plots
      const series_objects: Array<Highcharts.SeriesOptionsType> = spectral_datas.map(
        (spectral_data, i) => {
          const {
            data,
            direction,
          } = spectral_data;

          // add X values
          const freq_increment = +(max_freq / data.length).toFixed(2);
          const series_data = data.map(
            (value, j) => [freq_increment * j, value]
          );

          const series: Highcharts.SeriesOptionsType = {
            id: `${i}`,
            name: `${direction} axis`,
            data: series_data,
            type: 'line'
          };

          return series;
        });

      return {
        chart: {
          type: 'line',
          marginLeft: 80, // Keep all charts left aligned
          spacingTop: 10,
          spacingBottom: 10,
          alignTicks: false,
          backgroundColor: theme.graphs.chartBackground,
          style: {
            color: theme.graphs.appText,
          },
          animation: false,
          zoomType: 'xy',
          height: '150px',
        },
        // chart title on the top left
        title: {
          text: title,
          align: 'left',
          x: 30,
          style: {
            color: theme.graphs.appText,
            fontSize: theme.graphs.fontSize,
            fontFamily: theme.typography.fontFamily,
            marginLeft: 80,
          },
        },
        credits: {
          enabled: false,
        },
        colors: theme.graphs.series,
        xAxis: {
          crosshair: true,
          events: {
            setExtremes: syncExtremes,
          },
          labels: {
            format: '{value} Hz',
            style: {
              color: theme.graphs.dimmerText,
            },
          },
          lineColor: theme.graphs.chartBackground,
          tickColor: theme.graphs.dimmerText,
          max: max_freq,
          plotLines: drawAnnotationLines(annotations, rpm),
        },
        yAxis: {
          events: {
            setExtremes: syncExtremes,
          },
          title: {
            text: 'Amplitude',
            style: {
              color: theme.graphs.dimmerText,
            },
          },
          labels: {
            style: {
              color: theme.graphs.dimmerText,
            },
          },
          // max is the the max value of all the plots rounded up to the next 0.5
          max: y_max,
          min: 0,
          // this always create 5 ticks
          tickInterval: y_max / 4,
          gridLineColor: theme.graphs.dimmerText,
        },
        legend: {
          enabled: legend_status,
          itemStyle: {
            color: theme.graphs.appText,
          },
          itemHiddenStyle: {
            color: '#707070',
          },
          itemHoverStyle: {
            color: '#FFFFFF',
          },
          align: 'center',
          verticalAlign: 'top',
          floating: true,
          x: 0,
          y: 0,
        },
        plotOptions: {
          area: {
            keys: ['x', 'y', 'marker'],
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, theme.graphs.diq_yellow],
                [1, theme.graphs.transparent],
              ],
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
          },
          series: {
            // toggle the series using the first chart legend
            events: { legendItemClick },
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: false,
              },
            },
          },
        },
        tooltip: {
          positioner: function(labelWidth: number) {
            return {
              // right aligned
              x: (this as Highcharts.Tooltip).chart.chartWidth - labelWidth,
              y: 0, // align to title
            };
          },
          borderWidth: 0,
          backgroundColor: 'none',
          pointFormat: '{point.x:.2f} Hz',
          headerFormat: '',
          shadow: false,
          style: {
            fontSize: '1rem',
            color: theme.graphs.appText,
          },
          valueDecimals: 4,
        },
        series: series_objects,
      };
    }

    function toggleSeries(series: Highcharts.Series) {
      if (series) {
        if(series.visible) {
          series.hide();
        } else {
          series.show();
        }
      }
    }

    function legendItemClick(this: Highcharts.Series, event: Highcharts.SeriesLegendItemClickEventObject) {
      const id = this.options.id;
      if (!id) return;
      toggleSeries(this);
      Highcharts.each(Highcharts.charts, (chart: Highcharts.Chart) => {
        toggleSeries(chart.get(id) as Highcharts.Series);
      });
    }

    function syncExtremes(this: Highcharts.Axis, e: any) {
      var thisChart = this.chart;
      if (e.trigger !== 'syncExtremes') {
        const chartContainer = document.getElementById(CONTAINER_ID);
        if (!chartContainer) return;
        chartContainer.childNodes.forEach(node => {
          const indexStr = (node as HTMLElement).dataset['highchartsChart'];
          if (indexStr) {
            const chart = Highcharts.charts[parseInt(indexStr)];
            if (
              chart && chart !== thisChart &&
              (chart.options.chart as any).className ===
                (thisChart.options.chart as any).className
            ) {
              if (chart.xAxis[0].setExtremes && e.target.coll === 'xAxis') {
                chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                  trigger: 'syncExtremes',
                });
              }
              if (chart.yAxis[0].setExtremes && e.target.coll === 'yAxis') {
                chart.yAxis[0].setExtremes(e.min, e.max, undefined, false, {
                  trigger: 'syncExtremes',
                });
              }
            }
          }
        });
      }
    }

    function drawAnnotationLines(annotations: WaterfallAnnotations, rpm: number) {
      if (annotations) {
        return Object.keys(annotations).map((key, index) => {
          return {
            color: getAnnotationColor(index),
            value: annotations[key] * rpm,
            width: 3,
            zIndex: 0,
          };
        });
      }
      return [];
    }
  }

  function getAnnotationColor(index: number) {
    return theme.graphs.annotations[theme.graphs.annotations.length % index]
  }

  function drawAnnotationLegend() {
    if (!annotations) {
      return null;
    }
    // Add legend for annotations if present
    return (
      <div className="annotation-legend">
        Annotations:{' '}
        {Object.keys(annotations).map((key, index) => {
          return (
            <React.Fragment key={key}>
              <span
                className="legend-dot"
                style={{ color: getAnnotationColor(index) }}
              >
                ●
              </span>
              <span className="legend-item">{key}</span>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  function syncHighlights() {
    /**
     * In order to synchronize tooltips and crosshairs, override the
     * built-in events with handlers defined on the parent element.
     */
    ['mousemove', 'touchmove', 'touchstart'].forEach(eventType => {
      const container = document.getElementById('spectral-graphs');
      if (container) {
        container.addEventListener(eventType, e => {
          let chart;
          let point;
          let event;

          for (let i = 0; i < Highcharts.charts.length; i += 1) {
            chart = Highcharts.charts[i];

            if (chart && chart.series[0]) {
              // Find coordinates within the chart
              event = chart.pointer.normalize(e as any);
              // Get the hovered point
              point = (chart.series[0] as any).searchPoint(event, true);

              if (point) {
                point.onMouseOver();
              }
            }
          }
        });
      }
    });

    /**
     * Override the reset function, we don't need to hide the tooltips and
     * crosshairs.
     */
    Highcharts.Pointer.prototype.reset = () => {
      return undefined;
    };
  }
}

export default SpectralGraphs;

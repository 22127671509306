import React from 'react';
import {
  Asset,
} from "../../models";
import ModelTable from "./components/modelTable";
import {ModelsState} from "./ModelsSlice";

interface Props {
  modelState: ModelsState,
  // todo: this needs to be passed directly into the slice
  assetFilters: Asset[]
}

function ModelsPage(props: Props) {

  const {
    assetFilters
  } = props;

  return (
    <ModelTable
      assetFilters={assetFilters}
      search
      
      paging
    />
  );
}

export default ModelsPage;

import {connect} from 'react-redux';
import DataPage from "./Data";

const mapDispatchToProps = {  };
const mapStateToProps = (state: any) => ({
  dataState: state.data
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataPage);

import React, {useEffect, useState} from 'react';
import {fromUnixTime, getTime} from 'date-fns';
import {Options} from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import {MaintenanceData, TimeRange} from '../../models';
import {useTheme} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {isLoading} from '../../util/helpers';

interface Props {
  maintenanceData?: MaintenanceData[],
  timeRange: TimeRange,
  updateTimeRange: Function,
  workOrderTypes: string[],
}

function MaintenanceGraph(props: Props) {
  const theme = useTheme();

  const {
    maintenanceData,
    timeRange,
    updateTimeRange,
    workOrderTypes,
  } = props;

  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    setChartOptions({
      xAxis: {
        min: timeRange.start,
        max: timeRange.end,
      },
    });
  }, [timeRange.start, timeRange.end]);

  useEffect(() => {
    if (maintenanceData && workOrderTypes) {
      setChartOptions(initChartOptions(maintenanceData, [...workOrderTypes, 'SIMILAR']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceData, workOrderTypes]);

  // Loading state
  if (isLoading(maintenanceData)|| isLoading(workOrderTypes)) {
    return <Skeleton variant='rect' animation='wave' style={{
      width: '100%'
    }}/>
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={chartOptions}
    />
  );

  function setMinMax(hcEvent: Highcharts.ExtremesObject) {
    if (updateTimeRange && hcEvent) {
      updateTimeRange({ start: hcEvent.min, end: hcEvent.max });
    }
  }

  function initChartOptions(events: MaintenanceData[], seriesTypes: string[]): Options {
    const labels: any = {};
    const series: any = seriesTypes.reduce(
        (obj: any, key) => {
          obj[key] = [];
          return obj;
        }, {});
    if (Object.keys(series).length){
      events.forEach(event  => {
        const timestamp = getTime(fromUnixTime(event.start_timestamp));
        labels[timestamp] = event.external_id ? `${event.external_id} - ${event.title}` : `${event.title}`;
        series[event.type].push({name: event.type, x: timestamp, y: 0});
        if (event.rank) {
          series['SIMILAR'].push({name: event.type, x: timestamp, y: 0})
        }
      });
    }
    let min = null;
    let max = null;
    if (timeRange){
      min = timeRange.start;
      max = timeRange.end;
    }

    return {
      title: {
        text: 'Maintenance Events',
        align: 'left',
        style: {
          color: theme.graphs.appText,
          fontSize: theme.graphs.fontSize,
          fontFamily: theme.typography.fontFamily
        },
      },
      legend: {
        enabled: true,
        floating: true,
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        itemStyle: {
          color: theme.graphs.dimText,
        },
        itemHoverStyle: {
          color: theme.graphs.appText,
        },
      },
      time: {
        useUTC: false
      },
      chart: {
        type: 'scatter',
        backgroundColor: theme.graphs.chartBackground,
        plotBackgroundColor: theme.graphs.chartPlotBackground,
        borderRadius: theme.graphs.borderRadius,
        marginRight: theme.graphs.marginRight,
        marginLeft: theme.graphs.marginLeft,
        height: '150px',
        zoomType: 'x',
      },
      xAxis: {
        type: 'datetime',
        startOnTick: false,
        endOnTick: false,
        tickColor: theme.graphs.dimmerText,
        tickWidth: 0.5,
        lineColor: theme.graphs.chartPlotBackground,
        gridLineColor: theme.graphs.chartBackground,
        gridLineWidth: 1,
        gridZIndex: 0,
        labels: {
          style: {
            color: theme.graphs.dimmerText,
            fontSize: '0.7rem',
          },
        },
        dateTimeLabelFormats: {
          day: '%b %e',
        },
        ordinal: false,
        min: min,
        max: max,
        events: { setExtremes: setMinMax },
        plotLines: Object.entries(series).reduce((filtered: any, [type, data]: any) => {
        if (type === 'SIMILAR'){
          filtered = data.map((wo: any) => {
          return {
            color: 'white',
            width: 2,
            value: wo.x
          }
        });
        }
        return filtered;
      }, []),
      },
      yAxis: {
        min: 0,
        max: 0,
        minRange: 0.1,
        maxPadding: .01,
        lineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
        tickInterval: 1,
        gridLineColor: theme.palette.text.primary,
        lineColor: theme.palette.text.primary,
        labels: { enabled: false }
      },
      subtitle: {
        text: ''
      },
      colors: theme.graphs.series,
      series: Object.entries(series).map(([type, data]) => {
        let params: any = {
          name: type,
          data: data as any,
          showInNavigator: false,
          type: 'scatter'
        };

        if (type === 'SIMILAR'){
          params = {
            ...params,
            marker: {
              radius: 8
            },
            zIndex: -1,
            color: 'white'
          }
        }
        return params
      }),
      plotOptions: {
        series: {
          stickyTracking: false
        }
      },
      tooltip: {
        enabled: true,
        formatter: function () {
          return labels[this.x];
        },
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
    };
  }
}

export default MaintenanceGraph;